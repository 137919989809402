/* eslint-disable react-hooks/exhaustive-deps */
import { HeaderPool } from "./HeaderPool/headerPool";
import {
  ContainerContent,
  ContainerDashboard,
  ContainerLoading,
} from "./dashboardPoolStyle";
import { SubHeader } from "./SubHeaderPool/subHeaderPool";
import { MinerSection } from "./MinersSection/minersSection";
import { CardInfo } from "./CardInfo/cardInfo";
import { BlocksInfos } from "./BlocksInfos/blocksInfos";
import { useTranslation } from "react-i18next";
import { BsGraphUp } from "react-icons/bs";
import { RiMoneyDollarCircleLine, RiPercentLine } from "react-icons/ri";
import {
  GiDiamondHard,
  GiMoneyStack,
  GiClover,
  GiStoneBlock,
  GiMiner,
  GiLevelTwoAdvanced,
  GiLevelThreeAdvanced,
  GiLevelFourAdvanced,
} from "react-icons/gi";
import { PropagateLoader, PulseLoader } from "react-spinners";
import { FAQContainer } from "../../components/FAQContainer/faqContainer";
import { TbArrowBackUp as Back } from "react-icons/tb";
import { ChartInfo } from "./Chart/chart";
import { PaymentsSection } from "./PaymentsSection/paymentsSection";
import { Footer } from "../../components/Footer/footer";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PoolDashboardContext } from "../../contexts/poolDashboard";
import { ConfigContext } from "../../contexts/config";
import { Modal } from "./Modal/modal";
import { needCustomDashboard } from "../../services/Pools/custom";

export const DashboardPool = () => {
  const { name } = useParams();

  const { 
    getPoolData,
    poolData,
    setPoolData,
    isLoading,
    viewModal
  } = useContext(PoolDashboardContext);

  const { viewPayment, viewMiner, viewFaq, handleSelected } =
    useContext(ConfigContext);

  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      await getPoolData(name).then((res) => {
        setPoolData(res);
      });
    };
    fetchData();
  }, []);

  const findCustomDashboard = needCustomDashboard.find((elt) => elt.coin.toLowerCase() === name.toLowerCase())

  if (findCustomDashboard) {
    return findCustomDashboard.dashboard(poolData)
  }

  const activeMiners = poolData?.headers?.activeMiners;
  const actualLuck = poolData?.headers?.actualLuck;
  const algorithm = poolData?.headers?.algorithm;
  const fee = poolData?.headers?.fee;
  const foundBlocks = poolData?.headers?.foundBlocks;
  const hashrate = poolData?.headers?.hashrate;
  const luck = poolData?.headers?.luck;
  const luck10 = poolData?.headers?.luck10;
  const luck100 = poolData?.headers?.luck100;
  const minPayment = poolData?.headers?.minPayment;
  const netDiff = poolData?.headers?.netDiff;
  const from = poolData?.from;
  const totalPaid =
    from === "dynex" || from === "chinet" || from === "novasol"
      ? `${poolData?.headers?.totalPaid} miners`
      : poolData?.headers?.totalPaid;

  const first = {
    icons: [<RiMoneyDollarCircleLine />, <GiMoneyStack />, <RiPercentLine />],
    texts: [
      t("dash.main.cards.min_payment"),
      `${
        from === "foundationV2" || from === "dynex" || from === "chinet" || from === "novasol"
          ? t("dash.main.cards.payments_done")
          : t("dash.main.cards.total_payment")
      }`,
      t("dash.main.cards.fee"),
    ],
    values: [minPayment, totalPaid, fee],
  };
  const second = {
    icons: [<GiDiamondHard />, <GiMiner />, <GiStoneBlock />],
    texts: [
      t("dash.main.cards.network_diff"),
      t("dash.main.cards.miners"),
      t("dash.main.cards.blocks_found"),
    ],
    values: [netDiff, activeMiners, foundBlocks],
  };
  const third = {
    icons: [<BsGraphUp />, <BsGraphUp />, <GiClover />],
    texts: [
      "Pool Hashrate",
      t("dash.main.cards.algorithm"),
      t("dash.main.cards.actual_luck"),
    ],
    values: [hashrate, algorithm, actualLuck],
  };
  const fourth = {
    icons: [
      <GiLevelTwoAdvanced />,
      <GiLevelThreeAdvanced />,
      <GiLevelFourAdvanced />,
    ],
    texts: [
      t("dash.main.cards.luck_1"),
      t("dash.main.cards.luck_10"),
      t("dash.main.cards.luck_100"),
    ],
    values: [luck, luck10, luck100],
  };

  return (
    <ContainerDashboard>
      <>
        {isLoading && (
          <ContainerLoading>
            <PropagateLoader color="#00cccc" />
            <h1>
              {t("dash.main.loading")}{" "}
              <PulseLoader color="var(--gray-6)" margin={3} size={4} />
            </h1>
          </ContainerLoading>
        )}
        {viewModal && <Modal pool={name} />}
        <HeaderPool />
        <SubHeader name={name} />
        <ContainerContent>
          {viewPayment ? (
            <PaymentsSection
              from={poolData?.from}
              payments={poolData?.payments}
            />
          ) : viewMiner ? (
            <MinerSection miners={poolData?.miners} />
          ) : viewFaq ? (
            <div className="faq">
              <button onClick={() => handleSelected()}>
                {t("miner_dash.back")}
                <Back />
              </button>
              <FAQContainer />
            </div>
          ) : (
            <>
              <CardInfo
                icons={first.icons}
                texts={first.texts}
                values={first.values}
              />
              <CardInfo
                icons={second.icons}
                texts={second.texts}
                values={second.values}
              />
              <CardInfo
                icons={third.icons}
                texts={third.texts}
                values={third.values}
              />
              {
                from !== "xdag" &&
                <CardInfo
                  icons={fourth.icons}
                  texts={fourth.texts}
                  values={fourth.values}
                />
              }
              <span className="grafico">
                {poolData?.charts?.length && <ChartInfo dataChart={poolData.charts} />}
              </span>
              <BlocksInfos blocks={poolData?.blocks} from={poolData?.from} />
            </>
          )}
        </ContainerContent>
        <Footer />
      </>
    </ContainerDashboard>
  );
};
