import styled from "styled-components";
import "animate.css";

export const HeaderDashboardPools = styled.header`
  background-color: var(--color-bg-dashboard-pool-header);
  width: 100%;
  min-height: 72px;
  position: relative;

  & > div {
    width: 100%;
    height: 100%;
    max-width: 767px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: var(--color-bg-dashboard-pool-header);
    position: absolute;
    z-index: 100;
    box-shadow: -1px -2px 10px 0px var(--color-shadow-header);

    & > figure {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      & > a {
        display: flex;
        align-items: center;
        gap: 8px;

        & > img {
          width: 40px;
          height: 40px;
        }
        & > figcaption {
          font-weight: var(--f-weigth-2);
          font-family: var(--font-text);
          font-size: var(--f-size-2-title);
          color: var(--gray-5);
        }
      }
    }
    & > .desktop__menu {
      display: none;
    }
  }

  & > .optionsMenu {
    height: 335px;
    width: 100%;
    max-width: 767px;
    background: var(--color-bg-dashboard-menu-mobile);
    position: absolute;
    z-index: 10;
    left: 0;
    top: ${(props) => (props.isOpenMenu ? "0px" : "-350px")};
    transition: top 0.5s ease;
    animation: ${(props) =>
      props.isOpenMenu ? "fadeInDown 0.5s ease" : "fadeOutUp 1s ease"};
    box-shadow: -1px -2px 10px 0px var(--color-shadow-header);

    & > ul {
      width: 100%;
      max-width: 767px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      padding: 2px;
      margin-top: 62px;

      & > li {
        height: 47px;
        padding: 0 5px;
        display: flex;
        align-items: center;
        font-size: var(--f-size-3);
        font-weight: var(--f-weigth-2);
        letter-spacing: 1.5px;
        gap: 8px;
        font-family: var(--font-text);
        border-bottom: 1px solid var(--color-border-bottom-menu);

        &:nth-child(even) {
          background-color: var(--color-bg-menu-mobile-line-even);
          color: var(--color-text-even);

          & > button {
            color: var(--color-text-even);
          }
        }

        &:nth-child(odd) {
          background-color: var(--color-bg-menu-mobile-line-odd);
          color: var(--color-text-odd);

          a {
            height: 100%;
            display: flex;
            gap: 8px;
            align-items: center;
          }

          a:visited {
            text-decoration: none;
            color: var(--color-text-odd);
          }

          & > button {
            height: 100%;
            color: var(--color-text-odd);
          }
        }

        svg {
          padding-left: 3px;
          width: 20px;
          height: 20px;
        }

        & > button {
          height: 100%;
          background-color: transparent;
          display: flex;
          align-items: center;
          gap: 6px;
          font-size: var(--f-size-3);
          font-weight: var(--f-weigth-2);
          letter-spacing: 1.5px;
          padding: unset;
          font-family: var(--font-text);

          & > svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media (min-width: 767px) {
    & {
      display: flex;
      position: unset;
      padding: 8px 16px;
      box-shadow: -1px -2px 10px 0px var(--color-shadow-header);
    }

    & > div {
      position: unset;
      z-index: unset;
      background-color: unset;
      max-width: 1200px;
      box-shadow: unset;

      & > nav {
        display: none;
      }

      & > .desktop__menu {
        display: flex;
      }

      & > .optionsMenu {
        position: unset;
        z-index: unset;
        left: unset;
        background-color: unset;
        height: 100%;
        justify-content: center;
        max-width: unset;
        animation: unset;
        transition: unset;

        & > ul {
          display: flex;
          align-items: center;
          gap: 8px;

          & > li {
            height: 47px;
            padding: 0 5px;
            font-size: var(--f-size-3);
            font-weight: var(--f-weigth-2);
            letter-spacing: 1.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-family: var(--font-text);
            color: var(--gray-6);
            min-width: 100px;
            border-bottom: 2px solid transparent;

            &:hover {
              filter: brightness(1.4);
              cursor: pointer;
            }

            &:hover::before {
              animation: animate 0.4s linear forwards;
            }

            &::before {
              content: "";
              position: absolute;
              bottom: -2px;
              background: transparent;
              border-bottom: 2px solid transparent;
            }

            @keyframes animate {
              0% {
                width: 0;
                border-bottom-color: transparent;
              }

              100% {
                width: 100%;
                border-bottom-color: var(--color-border-hover-menu);
              }
            }
            & > a {
              height: 100%;
              display: flex;
              gap: 8px;
              align-items: center;
            }

            & > a:visited {
              text-decoration: none;
              color: var(--gray-6);
            }

            svg {
              width: 20px;
              height: 20px;
              color: var(--color-icons-header-desktop);
            }

            & > button {
              height: 100%;
              background-color: transparent;
              display: flex;
              align-items: center;
              gap: 6px;
              font-size: var(--f-size-3);
              font-weight: var(--f-weigth-2);
              letter-spacing: 1.5px;
              padding: unset;
              font-family: var(--font-text);
              color: var(--gray-6);

              & > svg {
                width: 24px;
                height: 24px;
                color: var(--color-icons-header-desktop);
              }
            }
          }
        }
      }
    }

    & > .mobile__menu {
      display: none;
    }
  }
`;
