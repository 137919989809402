export const faqDataPt = [
  {
    title: "Como retirar moedas?",
    description:
      "Os pagamentos são processados ​​de maneira automática a cada (API 'paymentInterval': 14400,) horas. Para obter o pagamento, você precisa atingir o limite mínimo de pagamento.",
  },
  {
    title: "Qual é o pagamento mínimo? Posso mudar isso?",
    description:
      "No momento não é possível mudar o pagamento mínimo em nossos pools. O pagamento mínimo é mostrado na página principal de cada pool de moeda. Por exemplo, para o pool de mineração FIRO, o pagamento mínimo é 0,5 FIRO. Qualquer recompensa acumulada por um determinado endereço de criptomoeda, só pode ser paga para esse endereço específico. Não é possível mesclar os saldos da carteira.",
  },
  {
    title: "Saldo não confirmado, o que isso significa?",
    description:
      "Cada bloco encontrado pelo pool precisa ser confirmado antes que o mesmo seja recompensado. Isso significa que uma certa quantidade de blocos deve passar após esse bloco. Verifique a seção 'Blocos' do pool para verificar quantos blocos são necessários para uma moeda específica. Por exemplo, para Bitcoin Gold, 100 blocos são necessários. Dez minutos por cada bloco em média = 20 horas são necessários, então o saldo é transferido de 'Não confirmado' para 'Não pago'. A confirmação do bloco requer um tempo diferente para cada uma das moedas.",
  },
  {
    title: "Eu não recebi nenhuma moeda. Mas o pagamento é mostrado no Pool.",
    description:
      "Normalmente, você só precisa esperar um pouco. Às vezes, você vê que o pagamento foi efetuado pelo grupo, mas sua carteira está vazia. Antes de tudo, verifique a blockchain da moeda que você minera. Você vê o pagamento na blockchain? Se sim -> apenas espere algum tempo. O software da carteira leva alguns minutos (ou até horas) para obter a quantidade necessária de confirmações de transação. Especialmente se você minera na carteira de exchages. Cada moeda tem um explorador de blockchain diferente. No entanto, o Tx ID do pagamento geralmente é clicável.",
  },
  {
    title: "Minerando por 4 (algumas) horas. Nenhuma recompensa recebida.",
    description:
      "Assim que o bloco for encontrado, você receberá sua recompensa. Por favor, espere mais um pouco. Usamos o sistema de recompensa PPLNS ou PPLNT. Você deve minerar enquanto o bloco é encontrado (mesmo se o bloco não for encontrado por você). O PPLNS e o PPLNT é um pool coletivo. Os mineradores trabalham em equipe para encontrar um bloco. Quando encontrado, eles dividem a recompensa do bloco com base em seu hashrate. Pode acontecer que em moedas com grande dificuldade demore muito para encontrar um bloco. Algumas horas ou às vezes até dias! Seja paciente ou selecione a moeda com uma dificuldade menor.",
  },
  {
    title: "O que é a sorte?",
    description:
      "A mineração é probabilística por natureza: se você encontrar um bloco mais cedo do que deveria estatisticamente, em média, você tem sorte se demorar mais, você tem azar. Em um mundo perfeito, você encontrará um bloco com 100% de valor de sorte. Menos de 100% significa que a pool de mineração teve sorte. Mais do que 100% significa que a pool de mineração teve azar.",
  },
  {
    title: "A sorte no pool é superior a 500%. Está tudo de acordo?",
    description:
      "Sim. Tudo está bem. Não se preocupe. A mineração é de natureza probabilística: caso encontre um bloco antes do programado, estatisticamente, em média, você terá sorte, se demorar mais, você terá azar. Em um mundo perfeito, você encontraria um bloqueio no valor de sorte de 100%. Menos de 100% significa que o pool teve sorte. Mais de 100% significa que o pool não teve sorte. Vimos 600%, 800% ou até 1500% de sorte. Isso poderia acontecer e nada que pudéssemos fazer.",
  },
  {
    title: "Eu estava minerando no endereço de carteira errado!",
    description:
      "Infelizmente, nada que possamos fazer para ajudá-lo. Outra pessoa receberá suas moedas. Não poderíamos mover nenhuma moeda de um para outro endereço se elas não tivessem sido enviadas do pool. Além disso, não poderíamos ajudá-lo se as moedas já foram enviadas. Sempre preste atenção no endereço da carteira digitado.",
  },
  {
    title: "Quanto eu poderia minerar com meu hashrate?",
    description:
      "Existem várias maneiras de estimar sua recompensa em potencial, no entanto, existe outra estratégia. Você pode ir para a página 'Mineradores online' no pool de sua escolha e encontrar o minerador com o hashrate que é semelhante ao seu. Dê uma olhada em suas estatísticas para ter uma idéia de quanto você poderia extrair. Este método funciona apenas se você selecionar o minerador que esteve online durante o período de tempo que você está procurando. Calculadoras que recomendamos:",
    links: [
      {
        link: "https://www.julianocaju.com.br/calculadora/",
        name: "Juliano Caju",
      },
      {
        link: "https://2cryptocalc.com/",
        name: "2cryptocalc",
      },
      {
        link: "https://whattomine.com/",
        name: "Whattomine",
      },
    ],
  },
];
