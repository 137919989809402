/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const [language, setLanguage] = useState("en");

  //const [language, setLanguage] = useState("ptBR");
  const [darkMode, setDarkMode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [display, setDisplay] = useState("flex");
  const [viewPayment, setViewPayment] = useState(false);
  const [viewMiner, setViewMiner] = useState(false);
  const [viewFaq, setViewFaq] = useState(false);
  const [select, setSelect] = useState(false);

  const { i18n } = useTranslation();

  useEffect(() => {
    verifyDarkModeLocalStorage();
    i18n.changeLanguage(language);
  }, [language]);

  const handleChecked = () => {
    setChecked((ischecked) => !ischecked);
setLanguage((lng) => (lng === "en" ? "ptBR" : "en"));
    //setLanguage((lng) => (lng === "ptBR" ? "en" : "ptBR"));
  };

  const verifyDarkModeLocalStorage = () => {
    const dark = localStorage.getItem("@ThreePoolDarkMode");

    if (dark === "true") {
      document.querySelector("html")?.classList.add("dark");
      setDarkMode(true);
    }
  };

  const handleDarkMode = () => {
    if (darkMode) {
      setDarkMode(false);
      localStorage.setItem("@ThreePoolDarkMode", false);
      document.querySelector("html")?.classList.remove("dark");
    } else {
      setDarkMode(true);
      localStorage.setItem("@ThreePoolDarkMode", true);
      document.querySelector("html")?.classList.add("dark");
    }
  };

  const handleSelected = (button) => {
    console.log(button)
    setSelect(button);
    if (button === undefined) {
      setViewMiner(false);
      setViewPayment(false);
      setViewFaq(false);
    }
    if (button === "button1") {
      setViewPayment(true);
      setViewMiner(false);
      setViewFaq(false);
    }
    if (button === "button2") {
      setViewMiner(true);
      setViewPayment(false);
      setViewFaq(false);
    }
    if (button === "faq") {
      setViewMiner(false);
      setViewPayment(false);
      setViewFaq(true);
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        checked,
        setChecked,
        language,
        handleChecked,
        darkMode,
        handleDarkMode,
        viewPayment,
        viewMiner,
        viewFaq,
        select,
        handleSelected,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
