import styled from "styled-components";

export const SubHeaderContainer = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  height: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 18px;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > button {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background-color: transparent;
      gap: 8px;
      ${({ select }) => (select ? "cursor: pointer;" : "cursor: default;")}

      img {
        width: 45px;
        height: 45px;
        ${({ select }) => (select ? "cursor: pointer;" : "cursor: default;")}
      }

      div {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: var(--f-size-2-title);
          font-weight: var(--f-weigth-2);
          font-family: var(--font-title);
          color: var(--gray-5);
          ${({ select }) => (select ? "cursor: pointer;" : "cursor: default;")}
        }

        span {
          color: var(--brand-dashboard);
          font-size: 12px;
          font-weight: bold;
        }
      }
    }
    & > .btn_server {
      width: 137px;
      padding: 4px;
      background-color: transparent;
      color: var(--gray-6);
      border: 2px solid transparent;
      border-bottom: 2px solid var(--color-border-button-subHeder);
      border-radius: 4px;
      font: var(--text-btn);
      text-align: center;
      cursor: pointer;
    }

    & > .btn_server:hover {
      filter: brightness(1.2);
      border-bottom: 2px solid var(--brand-dashboard);
      transition: all 0.2s ease-in;
    }
  }

  & > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    & > li {
      width: 133px;

      & > button {
        width: 100%;
        padding: 4px;
        background-color: transparent;
        color: var(--gray-6);
        border: 2px solid transparent;
        border-bottom: 2px solid var(--color-border-button-subHeder);
        border-radius: 4px;
        font: var(--text-btn);
        display: block;
      }

      & > button.selected {
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation: button-selected 1s ease-in-out forwards;
      }

      & > button:hover {
        filter: brightness(1.2);
        border-bottom: 2px solid var(--brand-dashboard);
        transition: all 0.2s ease-in;
      }

      @keyframes button-selected {
        0% {
          background-color: transparent;
          border-bottom: 2px solid var(--brand-dashboard);
          border-left: 2px solid transparent;
          border-top: 2px solid transparent;
          border-right: 2px solid transparent;
        }
        25% {
          border-left: 2px solid var(--brand-dashboard);
        }
        50% {
          border-top: 2px solid var(--brand-dashboard);
        }
        98% {
          border-right: 2px solid var(--brand-dashboard);
        }
        100% {
          width: 100%;
          background-color: var(--color-bg-button-subHeader-animation);
          color: var(--gray-0);
          border: 2px solid var(--brand-dashboard);
        }
      }
    }
  }

  @media (min-width: 767px) {
    & {
      flex-direction: row;
      padding: 0px 8px;
      & > div {
        width: 220px;
        justify-content: space-between;
        gap: 18px;
        padding-right: 10px;
      }

      & > ul {
        width: 320px;
        justify-content: center;
      }
    }
  }
`;
