import { HeaderLandingPage } from "./Header/headerLandingPage";
import { SectionCardPools } from "./SectionCardPools/sectionCardPools";
import { SectionFooter } from "./SectionFooter/sectionFooter";
import { SectionInfo } from "./SectionInfo/sectionInfo";
import { LandingPageBackground, SectionSlogan } from "./landingPageStyle";
import { useTranslation } from "react-i18next";

export const LandingPage = () => {
  useTranslation();

  return (
    <LandingPageBackground>
      <HeaderLandingPage />
      <main>
        <SectionSlogan className="container">
          {/* <h2>{t("landingPage.slogan")}</h2>
             { <button type="button">
            <a href="#pools"> {t("landingPage.btn-start")}</a>
          </button>} */}
        </SectionSlogan>
        <SectionInfo />
      </main>
      <SectionCardPools />
      <SectionFooter />
    </LandingPageBackground>
  );
};
