import styled from "styled-components"

export const Form = styled.form`
    width: 100%;
    max-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;

    & > input{
        border-radius: 6px;
        padding: 8px;
        width: 100%;
        max-width: 320px;
        text-align: center;
        background-color: var(--color-bg-input-subHeader);
        box-shadow: 3px 2px 4px var(--shadows-input-subHeader);
        color: var(--gray-6);
    }

    & > input::placeholder{
        color: var(--gray-6);
    }

    & > button{
        display: flex;
        align-items: center;
        justify-content:center;
        background-color: var( --brand-dashboard);
        width: 45px;
        height: 100%;
        padding: 9px;
        border-top-right-radius: 4px;
        border-bottom-right-radius:4px;
        position: absolute;
        right: 0px;
        cursor: pointer;
        box-shadow: 3px 2px 4px var(--shadows-input-subHeader);
        
        & > svg{
            color: #f2f2f2;
            width: 20px;
            height: 20px;
        }
    }

    @media (min-width: 767px) {
        display: flex;
        justify-content: center;
        width: 320px;

        & > input{
            max-width: unset;
            text-align: start;
        }

        & > button{
            height: 100%;
        }
    }
`