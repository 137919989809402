import { createContext, useState } from "react";

import { pool } from "../services/Pools/connection";

export const PoolDashboardContext = createContext({});

export const PoolDashboardProvider = ({ children }) => {
  const [poolData, setPoolData] = useState();
  const [customDashboard, setCustomDashboard] = useState({ need: false, components: {} })
  const [isLoading, setIsLoading] = useState(true);
  const [viewModal, setViewModal] = useState(false);

  const getPoolData = async (poolName) => {
    return await pool(poolName).then((res) => {
      setIsLoading(false);
      return res;
    });
  };

  return (
    <PoolDashboardContext.Provider
      value={{
        getPoolData,
        poolData,
        setPoolData,
        customDashboard,
        setCustomDashboard,
        isLoading,
        viewModal,
        setViewModal,
      }}
    >
      {children}
    </PoolDashboardContext.Provider>
  );
};
