import { useTranslation } from "react-i18next";
import {
  PaymentsTitle,
  PaymentsChange,
  PaymentsList,
  PaymentsCard,
  PaymentsWapper,
} from "./paymentSectionStyle";

export const PaymentsSection = ({ payments, from, setChangeSections }) => {
  const { t } = useTranslation();

  return (
    <PaymentsWapper>
      <PaymentsTitle>{t("miner_dash.table.payments")}</PaymentsTitle>
      <PaymentsChange>
        <button type="button" onClick={() => setChangeSections(true)}>
          {t("miner_dash.table.workers")}
        </button>
        <button type="button">{t("miner_dash.table.payments")}</button>
      </PaymentsChange>
      <PaymentsList>
        {payments?.length ? (
          payments?.map((payment, i) => (
            <PaymentsCard
              key={i}
              href={from === "miningCore" ? payment.link : undefined}
              disabled={from !== "miningCore"}
              target="_blank"
              rel="noreferrer"
            >
              <p>
                {t("miner_dash.table.payed_at")}:{" "}
                <span>{payment.findedAt}</span>
              </p>
              <p>
                TxID:
                <span>{payment.id}</span>
              </p>
              <p>
                {t("miner_dash.table.reward")}:<span>{payment.reward}</span>
              </p>
            </PaymentsCard>
          ))
        ) : (
          <h2>{t("miner_dash.table.notfound.payments")}</h2>
        )}
      </PaymentsList>
    </PaymentsWapper>
  );
};
