import { InfoCard } from "./cardInfoStyle";

export const CardInfo = ({ tag = "", icons, texts, values }) => {
  return (
    <InfoCard id={tag}>
      {icons.map((elt, i) => {
        return (
          <span className={i === icons.length - 1 ? "noborder" : ""}>
            <span>
              {elt}
              <p>{texts[i]}</p>
            </span>
            <p className="textValues">{values[i] ? values[i] : "0"}</p>
          </span>
        )
      })}
    </InfoCard>
  );
};
