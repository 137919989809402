import styled from "styled-components";

export const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  width: 100%;
  height: 120px;
  margin: 30px auto 0px auto;
  background-color: #1b1a1a;

  & > p {
    color: var(--gray-3);
    font-weight: 700;
  }

  & > a {
    color: #f1f1f1;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 6px;
    transition: 0.3s;

    svg {
      font-size: 20px;
    }
  }

  & > a:active {
    color: #9099ff;
    transition: 0.3s;

    svg {
      color: #5662f6;
      transition: 0.3s;
    }
  }

  & > .contact {
    color: var(--color-title-coin-dashboard);
  }

  @media (min-width: 768px) {
    & > a:hover {
      color: #9099ff;
      transition: 0.3s;

      svg {
        color: #5662f6;
        transition: 0.3s;
      }
    }
  }
`;
