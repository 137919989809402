import styled from "styled-components";

export const PaymentsContainer = styled.section`
  width: 100%;
  margin-top: 20px;
  padding: 0 0 20px 0;
  background-color: var(--color-bg-dashboard-pool-header);
  border-radius: 16px 16px 0 0;
  box-shadow: var(--sh);

  #tagTitle {
    background-color: var(--color-title-coin-dashboard);
    border-radius: 16px 16px 0 0;
    font-weight: var(--font-weigth-1);
    display: flex;
    justify-content: space-between;
    padding: 16px;

    & > p {
      color: var(--gray-1);
    }
  }

  & > ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      padding-top: 20px;
      text-align: center;
      color: var(--gray-5);
      font-weight: bold;
      font-size: var(--text-1);
    }
  }

  @media (max-width: 767px) {
    & > div > p {
      display: none;
    }
  }
`;

export const PaymentCell = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid var(--color-border-cardInfo);
  padding: 20px 10px;

  &:nth-child(even) {
    background-color: var(--color-bg-menu-mobile-line-even);
    color: var(--color-text-even);
  }

  &:nth-child(odd) {
    background-color: var(--color-bg-menu-mobile-line-odd);
    color: var(--color-text-odd);
  }

  & > li {
    width: 100%;
    margin: 16px 0;
    display: flex;
    justify-content: space-between;

    span {
      display: none;
    }
  }

  p {
    color: var(--gray-4);
  }

  @media (max-width: 767px) {
    & > li {
      text-align: center;
      word-wrap: break-word;
      line-height: 22px;
      letter-spacing: 0.8px;
      flex-direction: column;
      gap: 16px;

      span {
        display: unset;
        letter-spacing: 1.5px;
        color: var(--brand-dashboard);
        font-weight: var(--f-weigth-1);
      }
    }
  }
`;
