import { faqDataPt } from "../../services/FAQ/faqPt";
import { faqDataEn } from "../../services/FAQ/faqEn";
import { useContext } from "react";
import { ConfigContext } from "../../contexts/config";
import { FaAngleDoubleDown as Arrow } from "react-icons/fa";
import {
  Container,
  Content,
  Text,
  Title,
  Bar,
  LinksContainer,
} from "./faqContainerStyle";

export const FAQContainer = ({ dark }) => {
  const { language } = useContext(ConfigContext);
  const dataLanguageFaq = language === "ptBR" ? faqDataPt : faqDataEn;
  return (
    <Container id="FaqDashboard">
      {dataLanguageFaq.map((elem, index) => (
        <Content div key={index}>
          <summary id="title">
            <Title>
              {elem.title} <Arrow />
            </Title>
            <Bar />
          </summary>
          <Text>{elem.description}</Text>
          {elem.links && (
            <LinksContainer>
              {elem.links.map((link, index) => (
                <a
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={link.link}
                >
                  {link.name}
                </a>
              ))}
            </LinksContainer>
          )}
        </Content>
      ))}
    </Container>
  );
};
