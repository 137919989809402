import { databaseImg } from "../../../../services/imgDB/imgDB";
import { SubHeaderContainer } from "./subHeaderXdagStyles";
import Logo from "../../../../assets/logo.png";
import { MinerSearch } from "../../../../components/MinerSearch/minerSearch";
import { dataMaintenance } from "../../../../services/maintenance/maintenanceDb";
import { useContext } from "react";
import { ConfigContext } from "../../../../contexts/config";
import { useTranslation } from "react-i18next";
import { PoolDashboardContext } from "../../../../contexts/poolDashboard";

export const SubHeaderXdag = ({ name }) => {
    const { select, handleSelected } = useContext(ConfigContext);
    const { setViewModal } = useContext(PoolDashboardContext);
    const { t } = useTranslation();

    const findMaintenace = dataMaintenance.find((elt) => elt.poolName === name);

    const handleName = () => {
        const verify = /^[A-Za-z]+$/;
        if (verify.test(name)) {
            const arr = name.split("");
            arr[0] = arr[0].toUpperCase();
            return `${arr.join("").toUpperCase()}`;
        } else {
            return name.toUpperCase();
        }
    };

    const criptoData = databaseImg.find((elt) => {
        return elt.nome.toLowerCase() === name.toLowerCase();
    });

    return (
        <SubHeaderContainer select={select}>
            <div>
                <button onClick={() => handleSelected(undefined)}>
                    <img
                        src={criptoData !== undefined ? criptoData.img : Logo}
                        alt={`Criptodata ${criptoData !== undefined ? criptoData.nome : name
                            }`}
                    /> 
                    <div>
                        <h1>{handleName()}</h1>
                        {findMaintenace && <span>{t("dash.main.maintenance")}</span>}
                    </div>
                </button>
            </div>
            <ul>
                <li>
                    <button className="btn_server" onClick={() => setViewModal(true)}>
                        {t("dash.main.nav.server")}
                    </button>
                </li>
            </ul>
            <MinerSearch name={name} />
        </SubHeaderContainer>
    );
};
