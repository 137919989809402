
import dero from "../../assets/cards/DERO.png";
import xdag from "../../assets/cards/XDAG.png";
import kaspa from "../../assets/cards/KASPA.png";


export const databaseImg = [
  
  {
    nome: "dero",
    img: dero,
  },

  {
    nome: "xdag",
    img: xdag,
  },
  {
    nome: "kas_solo",
    img: kaspa,
  },


];