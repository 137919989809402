/* eslint-disable jsx-a11y/alt-text */
import { useContext } from "react";
import { ConfigContext } from "../../contexts/config";
import { Button } from "./languageSwitchStyle";
import br from "../../assets/brasil.png";
import eua from "../../assets/estados-unidos.png";

export const LanguageSwitch = () => {
  const { checked, handleChecked } = useContext(ConfigContext);

  return (
    <>
      <Button onClick={handleChecked} id="switch">
        {!checked ? <img src={br} /> : <img src={eua} />}
        {!checked ? "EN" : "PT-BR"}
      </Button>
    </>
  );
};
