import dero from "../../assets/cards/DERO.png";
import xdag from "../../assets/cards/XDAG.png";
import kaspa from "../../assets/cards/KASPA.png";
import usa from "../../assets/brasil.png"
import brazil from "../../assets/estados-unidos.png"
import europe from "../../assets/uniao-europeia.png"


export const databaseServerEn = [
  {
    moeda: "DERO",
    img: dero,
    descricao:
    {
      descricao:
        "Miner Configuration: \n\nThis is the basic guide how to setup your miner to this pool",
            
     exemplo: 
     "To get started mining and use this pool you need the following \n\n. Dero GUI Wallet address, or Exchange wallet address of your choice \n\n. Mining software that supports the Dero coin and aglorithm AstroBWT/v3 \n\n. For Nvidia GPUs we recommend AstroNV \n\n. For Nvidia CPUs we recommend Astrominer \n\n. For AMD CPUs we recommend Deroluna",
          },
    local: [
      {
        pais: "EU",
        flag: <img width={24} src={europe} alt="Europe" />,
        server: [
          {
            endereco: "stue.threepool.tech:3030",
            dificuldade: "CPU/GPU",
            status: "Online",
          },
        ],
      },
      {
        pais: "US",
        flag: <img width={24} src={usa} alt="USA" />,
        server: [
          {
            endereco: "stus.threepool.tech:3030",
            dificuldade: "CPU/GPU",
            status: "Online",
          },
        ],
      },
      {
        pais: "BR",
        flag: <img width={24} src={brazil} alt="Brazil" />,
        server: [
          {
            endereco: "stbr.threepool.tech:3030",
            dificuldade: "CPU/GPU",
            status: "Online",
          },
        ],
      },
    ],
  },

  {
    moeda: "XDAG",
    img: xdag,
    descricao:
    {
      descricao:
        "Miner Configuration: \n\nThis is the basic guide how to setup your miner to this pool",
            
     exemplo: 
     "To get started mining and use this pool you need the following \n\n. XDAG GUI wallet address or Exchange wallet address of your choice \n\n. Mining software that supports the XDAG coin and aglorithm RandomX \n\n. We recommend Xmrig Miner \n\n. Node with FEE 1%",
          },
    local: [
      {
        pais: "BR",
        flag: <img width={24} src={brazil} alt="Brazil" />,
        server: [
          {
            endereco: "xdag.threepool.tech:3030",
            dificuldade: "CPU",
            status: "Online",
          },
        ],
      },
      
      
    ],
  },
  
  {
    moeda: "KAS_SOLO",
    img: kaspa,
    descricao:
    {
      descricao:
        "Miner Configuration: \n\nThis is the basic guide how to setup your miner to this pool",
            
     exemplo: 
     "To get started mining and use this pool you need the following \n\n. KASPA wallet address or Exchange wallet address of your choice \n\n. Mining software that supports the KASPA coin and aglorithm Kheavyhash",
          },
    local: [
      {
        pais: "BR",
        flag: <img width={24} src={brazil} alt="Brazil" />,
        server: [
          {
            endereco: "stbr.threepool.tech:4096",
            dificuldade: "KS0/PRO",
            status: "Online",
          },
        ],
      },
      {
        server: [
          {
            endereco: "stbr.threepool.tech:4097",
            dificuldade: "KS1/KS2",
            status: "Online",
          },
        ],
      },
      {
        server: [
          {
            endereco: "stbr.threepool.tech:4098",
            dificuldade: "KS3",
            status: "Online",
          },
        ],
      },
      {
        server: [
          {
            endereco: "stbr.threepool.tech:4060",
            dificuldade: "NICEHASH",
            status: "Online",
          },
        ],
      },
      
      
    ],
  },
];
