import { useContext } from "react";
import { LandingPageContext } from "../../../contexts/landingPage";
import { ContainerCardPools, UlPool, Load } from "./sectionCardPoolsStyle";
import { useTranslation } from "react-i18next";
import { Card } from "../../../components/Card/card";

export const SectionCardPools = () => {
  const { pools } = useContext(LandingPageContext);
  const { t } = useTranslation();

  return (
    <ContainerCardPools id="pools">
      <h2>{t("landingPage.sectionCardPools.title")}</h2>
      <UlPool className="container">
        {pools.flat()[0] ? (
          pools.flat().map((elt, i) => <Card key={i} coin={elt} />)
        ) : (
          <Load>Loading...</Load>
        )}
      </UlPool>
    </ContainerCardPools>
  );
};
