import {
  ContainerInfo,
  SectionCardInfo,
  //SectionSubTitle,
} from "./sectionInfoStyle";
import { MdOutlineDoubleArrow as Arrow } from "react-icons/md";
import { useTranslation } from "react-i18next";

export const SectionInfo = () => {
  const { t } = useTranslation();
  return (
    <ContainerInfo>
      <SectionCardInfo>
        <div className="cardInfo">
          <h3>{t("landingPage.sectionInfo.joinUs")}</h3>
          <p>
            {t("landingPage.sectionInfo.brasilian_pools_thinking")}
            <span className="title"> {t("landingPage.sectionInfo.you")}</span>
          </p>
        </div>

        <div className="cardInfo">
          <Arrow />
          <p>{t("landingPage.sectionInfo.chose_coins")}</p>
        </div>

        <div className="cardInfo">
          <p>
            <Arrow />
            {t("landingPage.sectionInfo.track_data")}
            <span className="title"> ThreePool.Tech</span>{" "}
            {t("landingPage.sectionInfo.search_wallet")}
          </p>
        </div>
      </SectionCardInfo>
{/*       <SectionSubTitle className="container">
        <h2>{t("landingPage.sectionSubTitle.coins_we_mine")}</h2>
        <p>
          <span>Threepool.tech</span>{" "}
          {t("landingPage.sectionSubTitle.brasilian_pools")}
          <span> {t("landingPage.sectionSubTitle.multi_coin")}</span>
          {t("landingPage.sectionSubTitle.mine_a_lot")}
        </p>
      </SectionSubTitle> */}
    </ContainerInfo>
  );
};
