import axios from "axios";
import {
  convertDate,
  convertHashrate,
  formatLargeNumbers,
  getHashrateDivisor,
} from "./utils";

// Cria a base url para fazermos as requisições nas outras funções.
const baseUrl = axios.create({
  baseURL: "https://apik.threepool.tech/api/pools",
  timeout: 10000,
});

// Lista o nome das pools.
export let getMiningCorePoolsKas = async () => {
  return await getMiningCoreStatsKas().then((res) => {
    return res.pools.map((elt) => elt.id);
  });
};

// Busca informações gerais da API.
const getMiningCoreStatsKas = async () => {
  const res = await baseUrl.get("/");

  return res.data;
};

// Busca informações gerais de uma pool.
const getMiningCoreInfoKas = async (name) => {
  const resp = await baseUrl.get(`/${name}`);

  return resp.data;
};

// Busca informações referente aos blocos de uma pool.
const getMiningCoreBlocksKas = async (name) => {
  const resp = await baseUrl.get(`/${name}/blocks?pageSize=300`);

  return resp.data;
};

// Busca informações referente ao pagamento de uma pool.
const getMiningCorePaymentsKas = async (poolName, limit) => {
  const resp = await baseUrl.get(`/${poolName}/payments?pageSize=100`);

  return resp.data;
};

// Busca informações para gerar o gráfico.
const getMiningCoreChartsKas = async (poolName) => {
  const resp = await baseUrl.get(`/${poolName}/performance`);

  return resp.data.stats;
};

// Busca informações referentes aos mineradores de uma pool e cria um limite.
const getMiningInfoMinersKas = async (poolName, limit) => {
  const resp = await baseUrl.get(`/${poolName}/miners?pageSize=100`);
  return resp.data;
};

// Busca informações gerais de um minerador.
const getMinerMiningInfoKas = async (poolName, miner) => {
  const resp = await baseUrl.get(`/${poolName}/miners/${miner}`);

  return resp.data;
};

// Busca informações dos pagamentos de um minerador
const getMinerMiningPaymentsInfoKas = async (poolName, miner) => {
  const resp = await baseUrl.get(`/${poolName}/miners/${miner}/payments`);

  return resp.data;
};

// Função que recebe dados e transforma no Card exibido na landing page.
const joinMiningCoreCardInfoObjKas = (data) => {
  const dashPoolEffort = (data) => {
    let PoolEffort;
  
    if ('poolEffort' in data) {
      // alephium (family)
      if (data.coin.family === "alephium") {
        PoolEffort = Number(data.poolEffort) * Math.pow(2, 30) * 100;
      // bitcoin (family)
      } else if (data.coin.family === "bitcoin") {
        // Vertcoin (coin)
        if (data.coin.name === "Vertcoin") {
          PoolEffort = Number(data.poolEffort) / 256;
        } else {
          PoolEffort = Number(data.poolEffort) * 100;
        }
      // kaspa (family)
      } else if (data.coin.family === "kaspa") {
        PoolEffort = Number(data.poolEffort) * Math.pow(2, 31) * (Math.pow(2, 256) / (Math.pow(2, 255) - 1)) * 100;
      } else {
        PoolEffort = Number(data.poolEffort) * 100;
      }
    }
  
    return PoolEffort;
  };
  //console.log (dashPoolEffort(data));							
  return {
    id: data.id,
    name: data.coin.name,
    symbol: data.coin.symbol,
    hashrate: convertHashrate(data.poolStats.poolHashrate),
    activeMiners: data.poolStats.connectedMiners,
    luck: (dashPoolEffort(data)).toFixed(2) + "%",
    fee: data.poolFeePercent + "%",
    paymentInterval: "1h",
    minPayment: `${data.paymentProcessing.minimumPayment} ${data.coin.symbol}`,
    payoutSchemeKas: data.paymentProcessing.payoutScheme,
    from: "miningCoreKaspa",
  };
};

// Função que recebe dados e transforma em um bloco que dados a serem usados na dashboard.
const joinMiningCorePoolInfoObjKas = (data, blocks, payments, miners, charts) => {
  // console.log(blocks)
  const firstBlock = blocks.find(
    (block) => block.status === "confirmed" && block.type !== "uncle" && block.status !== "orphaned"
  );
  const first10Blocks = [];
  blocks.forEach((block) => {
    if (first10Blocks.length <= 10 && block.type !== "uncle" && block.status !== "orphaned") {
      first10Blocks.push(block);
    }
  });
  const first100Blocks = blocks.filter(
    (block) => block.status === "confirmed" && block.type !== "uncle" && block.status !== "orphaned"
  );

  const luck = firstBlock
    ? (firstBlock.effort * 100 || 0).toFixed(2) + "%"
    : "0%";
  const luck10 = first10Blocks.length
    ? (
        first10Blocks.reduce((acc, act) => acc + act.effort * 100, 0) || 0
      ).toFixed(2) + "%"
    : "0%";
  const luck100 =
    (
      first100Blocks.reduce((acc, act) => acc + act.effort * 100, 0) || 0
    ).toFixed(2) + "%";

  const filteredBlocks = blocks.filter((block) => block.type !== "uncle");

  const formatedBlocks = filteredBlocks.map((block) => {
    return {
      height: block.blockHeight,
      hash: block.hash,
      type: "",
      link: block.infoLink,
      wallet: block.miner,
      date: convertDate(block.created),
      luck: (block.effort * 100 || 0).toFixed(2) + "%",
      reward: block.reward.toFixed(3),
      progress: block.confirmationProgress,
      status: block.status
    };
  });

  const formatedPayments = payments.map((payment) => ({
    address: payment.transactionConfirmationData,
    reward: `${payment.amount.toFixed(4)} ${data.coin.symbol}`,
  }));

  const divisor = getHashrateDivisor(charts[charts.length - 1].poolHashrate);
  const fPoolEffort = (data) => {
    let PoolEffort;
  
    if ('poolEffort' in data) {
      // alephium (family)
      if (data.coin.family === "alephium") {
        PoolEffort = Number(data.poolEffort) * Math.pow(2, 30) * 100;
      // bitcoin (family)
      } else if (data.coin.family === "bitcoin") {
        // Vertcoin (coin)
        if (data.coin.name === "Vertcoin") {
          PoolEffort = Number(data.poolEffort) / 256;
        } else {
          PoolEffort = Number(data.poolEffort) * 100;
        }
      // kaspa (family)
      } else if (data.coin.family === "kaspa") {
        PoolEffort = Number(data.poolEffort) * Math.pow(2, 31) * (Math.pow(2, 256) / (Math.pow(2, 255) - 1)) * 100;
      } else {
        PoolEffort = Number(data.poolEffort) * 100;
      }
    }
  
    return PoolEffort;
  };
  console.log (fPoolEffort(data));
  return {
    headers: {
      minPayment: `${data.paymentProcessing.minimumPayment.toFixed(4)} ${
        data.coin.symbol
      }`,
      totalPaid: `${data.totalPaid.toFixed(3)} ${data.coin.symbol}`,
      fee: data.poolFeePercent + "%",
      netDiff: convertHashrate(data.networkStats.networkDifficulty),
      activeMiners: data.poolStats.connectedMiners,
      foundBlocks: data.totalBlocks,
      hashrate: convertHashrate(data.poolStats.poolHashrate),
      algorithm: data.coin.algorithm,
      actualLuck: ( fPoolEffort(data).toFixed(2)) + "%",
      luck,
      luck10,
      luck100,
    },
    blocks: {
      confirmed: formatedBlocks.filter((elt) => elt.progress === 1 && elt.status !== "orphaned"),
      pending: formatedBlocks.filter((elt) => elt.progress !== 1 && elt.status !== "orphaned"),
      orphaned: formatedBlocks.filter((elt) => elt.status === "orphaned"),
    },
    payments: formatedPayments,
    miners: miners.map(({ miner, hashrate }) => ({
      wallet: miner,
      hashrate: convertHashrate(hashrate),
    })),
    charts: charts.map((elt) => ({
      hashrate: (+elt.poolHashrate / +divisor).toFixed(2),
      date: elt.created,
    })),
    from: "miningCoreKas",
  };
};

// Função que recebe dados e transforma em um bloco que dados a serem usados na página do minerador.
const joinMiningCoreMinerInfoObjKas = (wallet, data, payments) => {
  const workersData = data.performance
    ? data.performance.workers
    : data.performanceSamples[0].workers;
  const keys = Object.keys(workersData);
  let hashrate = 0;
  let shares = 0;

  const workers = [];

  keys?.forEach((key) => {
    hashrate += workersData[key].hashrate;
    shares += workersData[key].sharesPerSecond;

    workers.push({
      worker: key,
      hashrate: convertHashrate(workersData[key].hashrate),
    });
  });

  return {
    headers: {
      wallet,
      hashrate: convertHashrate(hashrate),
      sharesPerSecond: shares.toFixed(2) + "/s",
      pendingShares: formatLargeNumbers(data.pendingShares),
      payment: data.totalPaid.toFixed(4),
      pendingPayment: data.pendingBalance.toFixed(4),
      todayPayment: data.todayPaid.toFixed(4),
    },
    workers,
    payments: payments.map((payment) => ({
      id: payment.transactionConfirmationData,
      findedAt: convertDate(payment.created),
      link: payment.transactionInfoLink,
      reward: payment.amount.toFixed(4),
    })),
    from: "miningCoreKas",
  };
};

// Função responsável por criar os cards das pools na landing page.
export const getMiningCoreCardsKas = async () => {
  const miningCoreInfo = [];

  await getMiningCoreStatsKas().then((info) =>
    info.pools.forEach((pool) =>
      miningCoreInfo.push(joinMiningCoreCardInfoObjKas(pool))
    )
  );

  return miningCoreInfo;
};

export const getMiningCoreDashboardDataKas = async (poolName) => {
  const data = await getMiningCoreInfoKas(poolName);
  const blocks = await getMiningCoreBlocksKas(poolName);
  const payments = await getMiningCorePaymentsKas(poolName);
  const miners = await getMiningInfoMinersKas(poolName);
  const charts = await getMiningCoreChartsKas(poolName);

  const formatedData = joinMiningCorePoolInfoObjKas(
    data.pool,
    blocks,
    payments,
    miners,
    charts
  );

  return formatedData;
};

export const getMiningCoreMinerDataKas = async (poolName, address) => {
  const data = await getMinerMiningInfoKas(poolName, address);
  const payments = await getMinerMiningPaymentsInfoKas(poolName, address);

  const formatedData = joinMiningCoreMinerInfoObjKas(address, data, payments);

  return formatedData;
};
