import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  background-color: var(--gray-0);
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: var(--color-bg-dashboard-pool);
  margin: 0 auto;
  gap: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;

  h1 {
    font-size: 32px;
    color: var(--gray-6);
    display: flex;
    align-items: baseline;
    gap: 4px;
  }
`;

export const Container = styled.main`
  margin: 0 auto;
  width: 100vw;
  max-width: 1000px;
  height: 100%;
  min-height: 100vh;
`;

export const HeaderSection = styled.section`
  padding: 6px 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 18px;

  button {
    margin-top: 10px;
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: 1px;
    background-color: transparent;
    color: var(--gray-5);
    font-family: var(--font-text);
    font-size: var(--text-2);
    font-weight: bold;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      gap: 2px;
      cursor: pointer;

      img {
        height: 32px;
      }
    }

    div {
      width: 100%;
      height: 2px;
      background-color: var(--brand-dashboard);
    }
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    font-size: var(--text-2);
    background-color: var(--color-bg-dashboard-pool-header);
    box-shadow: 6px 5px 4px 0px var(--shadow-dash-pool);

    span {
      margin: 0 auto;
      padding: 22px 0;
      width: calc(100% - 32px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      ${({ from }) =>
        (from === "foundationV1" || from === "foundationV2") &&
        "border-bottom: 1px solid var(--color-border-cardInfo)"};
      gap: 10px;
      letter-spacing: 0.5px;
      font-weight: var(--f-weigth-2);

      h2 {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: var(--color-text-cardInfo);
        gap: 6px;

        svg {
          width: 20px;
          height: 20px;
          color: var(--color-icons-cardInfo);
        }
      }

      p {
        color: var(--color-text-cardIndo-values);
        font-size: var(--text-2);
        word-break: break-all;
        text-align: center;
      }
    }
  }

  @media (min-width: 768px) {
    button {
      font-size: 16px;
      gap: 3px;

      span {
        gap: 6px;
      }
    }

    & > div {
      span {
        flex-direction: row;

        p {
          font-size: var(--text-1);
        }
      }
    }
  }
`;

export const CardsSection = styled.section`
  position: relative;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;

  #minerDash {
    margin-right: 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const InfoSections = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
