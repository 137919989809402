import styled from "styled-components";
import "animate.css";

export const HeaderContainer = styled.header`
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  background-color: #fff;
  box-shadow: 0px -6px 22px #006666;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 100%;
  .containerLogo {
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      width: 60px;
      height: 60px;
    }
    h1 {
      color: #00cccc;
      font-size: var(--f-size-2-title);
      font-weight: var(--font-weigth-2);
    }
  }

  nav {
    div {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ul {
      display: ${(props) => (props.isOpen ? "flex" : "none")};
      flex-direction: column;
      gap: 4px;

      width: 767px;
      max-width: 100%;
      padding: 8px;

      background-color: #171717;
      box-shadow: -2px 7px 9px 0px #ffffff4f;

      position: absolute;
      top: 90px;
      left: 0px;
      right: 0px;

      z-index: 0;

      li {
        padding: 0 5px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          color: #ececec;
          font-weight: var(--font-weigth-2);
          font-size: var(--f-size-2);
          transition: 0.3s;

          svg {
            width: 25px;
            height: 25px;
            color: #ececec;
          }
        }

        button {
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          color: #ececec;
          font-weight: var(--font-weigth-2);
          font-size: var(--f-size-2);
          transition: 0.3s;
          background-color: transparent;

          svg {
            width: 25px;
            height: 25px;
            color: #006666;
          }
        }

        a:is(:hover, :active, :focus) {
          color: white;
          transition: 0.3s;

          svg {
            color: white;
            transition: 0.4s;
          }
        }
      }
    }
  }

  @media (min-width: 767px) {
    nav {
      .hamburger-react {
        display: none;
      }
      ul {
        display: flex;
        flex-direction: row;
        position: unset;
        background-color: unset;
        gap: 16px;
        box-shadow: none;
        width: 100%;
        z-index: unset;

        li {
          width: unset;

          a {
            width: fit-content;
            height: unset;
            svg {
              display: none;
            }
          }
          button {
            width: fit-content;
            height: unset;
            flex-direction: column;
            gap: unset;
            font-size: 0px;
          }
        }
      }
    }
  }
`;
