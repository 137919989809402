export const dataBase = [
  {
    name: "evrmore",
    link: "https://evr.cryptoscope.io/block/?blockheight=",
  },
  {
    name: "firo",
    link: "https://explorer.firo.org/block/",
  },
  {
    name: "hivecoin",
    link: "https://explorer.hivecoin.org/blockview.php?hash=",
  },
  {
    name: "neoxa",
    link: "https://explorer.neoxa.net/block/",
  },
  {
    name: "ravencoin",
    link: "https://api.ravencoin.org/block/",
  },
];
