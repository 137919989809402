export const faqDataEn = [
  {
    title: "How to withdraw coins?",
    description:
      "Payments are processed automatically every (API 'paymentInterval': 14400,) hours. To get paid, you need to reach the minimum payment threshold.",
  },
  {
    title: "What is the minimum payment? Can I change it?",
    description:
      "It is currently not possible to change the minimum payout on our pools. The minimum payout is shown on the main page of each coin pool. For example, for the FIRO mining pool, the minimum payout is 0.5 FIRO. Any reward accrued for a particular cryptocurrency address can only be paid out to that specific address. It is not possible to merge wallet balances.",
  },
  {
    title: "Unconfirmed balance, what does it mean?",
    description:
      "Each block found by the pool needs to be confirmed before it is rewarded. This means that a certain amount of blocks must pass after this block. Check the 'Blocks' section of the pool to see how many blocks are needed for a specific currency. For example, for Bitcoin Gold, 100 blocks are required. Ten minutes for each block on average = 20 hours is required, then the balance is transferred from 'Unconfirmed' to 'Unpaid'. Block confirmation requires a different time for each of the currencies.",
  },
  {
    title: "I didn't receive any coins. But the payout is shown in the Pool.",
    description:
      "Usually, you just have to wait a bit. Sometimes you see that the payment went through the group, but your wallet is empty. First of all, check the blockchain of the coin you mine. Do you see the payment on the blockchain? If yes -> just wait some time. The wallet software takes a few minutes (or even hours) to get the required amount of transaction confirmations. Especially if you mine in the exchange wallet. Each coin has a different blockchain explorer. However, the payment Tx ID is usually clickable.",
  },
  {
    title: "Mining for 4 (some) hours. No reward received.",
    description:
      "Once the block is found, you will receive your reward. Please wait a little longer. We use the PPLNS or PPLNT reward system. You must mine while the block is found (even if the block is not found by you). The PPLNS and PPLNT is a collective pool. Miners work as a team to find a block. When found, they split the block reward based on your hashrate. It may happen that on coins with great difficulty it takes a long time to find a block. A few hours or sometimes even days! Be patient or select the coin with a lower difficulty.",
  },
  {
    title: "What is luck?",
    description:
      "Mining is probabilistic in nature: if you find a block earlier than you statistically should, on average, you're lucky; if it takes longer, you're unlucky. In a perfect world, you'll find a block with 100% luck value. Less than 100% means the mining pool was lucky. More than 100% means the mining pool was unlucky.",
  },
  {
    title: "Luck in the pool is over 500%. Is everything okay?",
    description:
      "Yes. Everything is good. Do not worry. Mining is probabilistic in nature: if you find a block ahead of schedule, statistically, on average, you'll be lucky, if it takes longer, you'll be unlucky. In a perfect world, you would find a lock worth 100% luck. Less than 100% means the pool is lucky. More than 100% means the pool is unlucky. We've seen 600%, 800% or even 1500% luck. It could happen and nothing we could do.",
  },
  {
    title: "I was mining on the wrong wallet address!",
    description:
      "Unfortunately, nothing we can do to help you. Someone else will receive your coins. We couldn't move any coins from one address to another if they weren't sent from the pool. Also, we couldn't help you if the coins have already been sent. Always pay attention to the wallet address entered.",
  },
  {
    title: "How much could I mine with my hashrate?",
    description:
      "There are several ways to estimate your potential payoff, however there is another strategy. You can go to the 'Online Miners' page on the pool of your choice and find the miner with the hashrate that is similar to yours. Take a look at your stats to get an idea of ​​how much you could extract. This method only works if you select the miner that has been online during the time period you are looking for. Calculators we recommend:",
    links: [
      {
        link: "https://www.julianocaju.com.br/calculadora/",
        name: "Juliano Caju",
      },
      {
        link1: "https://2cryptocalc.com/",
        name1: "2cryptocalc",
      },
      {
        link2: "https://whattomine.com/",
        name2: "Whattomine",
      },
    ],
  },
];
