import styled from "styled-components";

export const Li = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 6px;

    p {
      font-weight: var(--font-weigth-1);
      color: var(--pools-text);
      cursor: pointer;
    }

    svg {
      width: 24px;
      height: 24px;
      color: var(--gray-6);
      cursor: pointer;
    }
  }

  p {
    color: var(--gray-6);
    cursor: pointer;
  }
`;
