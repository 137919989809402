import styled from "styled-components";

export const ContainerSectionFooter = styled.section`
  width: 100vw;
  background-color: var(--gray-0);
  padding: 16px;
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 52px;
  max-width: 400px;
  position: relative;

  h2 {
    color: var(--gray-6);
    font-size: var(--f-size-1-title);
    font-weight: var(--font-weigth-1);
    text-align: center;
  }

  span {
    color: var(--gray-4);
    font-size: var(--f-size-2);
    font-weight: var(--font-weigth-2);
  }

  p {
    color: var(--gray-6);
    line-height: 23px;
    font-size: var(--f-size-2);
  }
`;

export const Footer = styled.footer`
  padding: 16px;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 22px;

  background-color: var(--footer-bg);
  color: #f1f1f1;
`;

export const FAQSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > button {
    padding: 0;
    border: none;
    background-color: transparent;
    color: #e1e1e1;
    text-decoration: underline;
    font-size: var(--f-size-2);
    font-weight: bold;
    text-align: center;
    transition: 0.3s;

    :active {
      color: var(--brand-1);
      transition: 0.3s;
    }

    @media (min-width: 1024px) {
      :hover {
        color: var(--brand-1);
        transition: 0.3s;
      }
    }
  }
`;

export const Contacts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  & > p,
  a {
    color: #f1f1f1;
    display: flex;
    align-items: center;
    text-align: center;
    gap: 6px;
    transition: 0.3s;

    svg {
      font-size: 20px;
    }
  }

  a:active {
    color: #9099ff;
    transition: 0.3s;

    svg {
      color: #5662f6;
      transition: 0.3s;
    }
  }

  @media (min-width: 768px) {
    a:hover {
      color: #9099ff;
      transition: 0.3s;

      svg {
        color: #5662f6;
        transition: 0.3s;
      }
    }
  }
`;
