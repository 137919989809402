import dero from "../../assets/cards/DERO.png";
import xdag from "../../assets/cards/XDAG.png";
import kaspa from "../../assets/cards/KASPA.png";
import usa from "../../assets/brasil.png"
import brazil from "../../assets/estados-unidos.png"
import europe from "../../assets/uniao-europeia.png"
const databaseServer = [
{
  moeda: "DERO",
  img: dero,
  descricao:
  {
    descricao:
      "Configuração do mineiro: \n\nEste é o guia básico de como configurar seu minerador para este pool",
          
   exemplo: 
   "Para começar a minerar e usar este pool, você precisa do seguinte \n\n. Endereço da carteira Dero GUI ou endereço da carteira Exchange de sua escolha \n\n. Software de mineração que suporta a moeda Dero e o agloritmo AstroBWT/v3 \n\n. Para GPUs Nvidia recomendamos AstroNV \n\n. Para GPUs Nvidia recomendamos Astrominer \n\n. Para CPUs AMD recomendamos Deroluna",
        },
  local: [
    {
      pais: "EU",
      flag: <img width={24} src={europe} alt="Europe" />,
      server: [
        {
          endereco: "stue.threepool.tech:3030",
          dificuldade: "CPU/GPU",
          status: "Online",
        },
      ],
    },
    {
      pais: "US",
      flag: <img width={24} src={usa} alt="USA" />,
      server: [
        {
          endereco: "stus.threepool.tech:3030",
          dificuldade: "CPU/GPU",
          status: "Online",
        },
      ],
    },
    {
      pais: "BR",
      flag: <img width={24} src={brazil} alt="Brazil" />,
      server: [
        {
          endereco: "stbr.threepool.tech:3030",
          dificuldade: "CPU/GPU",
          status: "Online",
        },
      ],
    },
  ],
},

{
  moeda: "XDAG",
  img: xdag,
  descricao:
  {
    descricao:
      "Configuração do mineiro: \n\nEste é o guia básico de como configurar seu minerador para este pool",
          
   exemplo: 
   "Para começar a minerar e usar este pool, você precisa do seguinte \n\n. Endereço da carteira XDAG GUI ou endereço da carteira Exchange de sua escolha \n\n. Software de mineração que suporta a moeda XDAG e o agloritmo RandomX \n\n. Recomendamos o Minerador Xmrig \n\n. Node com FEE 1%",
        },
  local: [
    {
      pais: "BR",
      flag: <img width={24} src={brazil} alt="Brazil" />,
      server: [
        {
          endereco: "xdag.threepool.tech:3030",
          dificuldade: "CPU",
          status: "Online",
        },
      ],
    },
   
   
  ],
},

{
  moeda: "KAS_SOLO",
  img: kaspa,
  descricao:
  {
    descricao:
      "Configuração do mineiro: \n\nEste é o guia básico de como configurar seu minerador para este pool",
          
   exemplo: 
   "Para começar a minerar e usar este pool, você precisa do seguinte \n\n. Endereço da carteira KASPA ou endereço da carteira Exchange de sua escolha \n\n. Software de mineração que suporta a moeda KASPA e o agloritmo Kheavyhash",
        },
  local: [
    {
      pais: "BR",
      flag: <img width={24} src={brazil} alt="Brazil" />,
      server: [
        {
          endereco: "stbr.threepool.tech:4096",
          dificuldade: "KS0/PRO",
          status: "Online",
        },
      ],
    },
    {
      server: [
        {
          endereco: "stbr.threepool.tech:4097",
          dificuldade: "KS1/KS2",
          status: "Online",
        },
      ],
    },
    {
      server: [
        {
          endereco: "stbr.threepool.tech:4098",
          dificuldade: "KS3",
          status: "Online",
        },
      ],
    },
    {
      server: [
        {
          endereco: "stbr.threepool.tech:4060",
          dificuldade: "NICEHASH",
          status: "Online",
        },
      ],
    },
  ],
},
];

export { databaseServer };