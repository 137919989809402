import { Li } from "./infoPoolsStyle";
import { GiWarPick as Miner, GiClover as Luck } from "react-icons/gi";
import { MdOutlinePayments as Pay } from "react-icons/md";
import { TbClock as Clock } from "react-icons/tb";
import { RiMoneyDollarCircleLine as MinPayment } from "react-icons/ri";

import { AiOutlineLoading } from "react-icons/ai";
import { useTranslation } from "react-i18next";

export const InfoPoolsLi = ({
  activeMiners,
  luck,
  fee,
  interval,
  minPayment,
  from,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Li>
        <div>
          <Miner />
          <p>{t("landingPage.sectionCardPools.cards.active")}</p>
        </div>
        <p>{activeMiners}</p>
      </Li>
      {luck !== 0 ? (
        <Li>
          <div>
            <Luck />
            <p>{t("landingPage.sectionCardPools.cards.luck")}</p>
          </div>
          <p>{luck ? luck : <AiOutlineLoading />}</p>
        </Li>
      ) : (<></>)}
      <Li>
        <div>
          <Pay />
          <p>{t("landingPage.sectionCardPools.cards.fee")}</p>
        </div>
        <p>{fee}</p>
      </Li>
      <Li>
        <div>
          <Clock />
          <p>{t("landingPage.sectionCardPools.cards.interval")}</p>
        </div>
        <p>{interval}</p>
      </Li>
      
     {/*  <Li>
        <div>
          <MinPayment />
          <p>{t("landingPage.sectionCardPools.cards.minPayment")}</p>
        </div>
        <p>{minPayment}</p>
      </Li> */}

{minPayment ? (
        <Li>
          <div>
            <MinPayment />
            <p>{t("landingPage.sectionCardPools.cards.minPayment")}</p>
          </div>
          <p>{minPayment}</p>
        </Li>
      ) : (<></>)}


    </>
  );
};
