import { LiPools } from "./cardStyle";
import Logo from "../../assets/logo.png";
import { InfoPoolsLi } from "./InfoPools/infoPoolsLi";
import { databaseImg } from "../../services/imgDB/imgDB";
import { dataMaintenance } from "../../services/maintenance/maintenanceDb";
import { HashLink } from "react-router-hash-link";

export const Card = ({ coin }) => {
  const id = coin.id;
  const namePool = coin.name;
  const symbol = coin.symbol;
  const hashHate = coin.hashrate;
  const activeMiners = coin.activeMiners;
  const luck = coin.luck;
  const fee = coin.fee;
  const interval = coin.paymentInterval;
  const minPayment = coin.minPayment;
  const payoutScheme = coin.from === "miningCore" ? coin.payoutScheme : null;
  const payoutSchemeKas = coin.from === "miningCoreKaspa" ? coin.payoutSchemeKas : null;

  const imgLogo = databaseImg.find((elt) => {
    if (symbol === "FLR" && payoutScheme === "SOLO") {
      return elt.nome === "flora_solo";
    }
   else if (symbol === "NOVO" && payoutScheme === "SOLO") {
      return elt.nome === "novo_solo";   
    }

    return elt.nome.toLowerCase() === id.toLowerCase();
  });

  if (
    dataMaintenance.find(
      (pool) => pool.poolName.toLowerCase() === id.toLowerCase()
    )
  ) {
    return null;
  }

  return (
    <LiPools>
      <HashLink to={`/dashboard/${id.toLowerCase()}/#top`}>
        <div className="headerDivLi">
          <div className="containerPool">
            <img
              src={imgLogo !== undefined ? imgLogo.img : Logo}
              alt="cripto"
            />
<div className="namePool">
  <h3>{namePool.toUpperCase()}</h3>
  {symbol === "FLR" && payoutScheme !== null ? (
    <span>
      {symbol.toUpperCase()} = {payoutScheme}
    </span>
  ) : symbol === "NOVO" ? (
    <span>
      {symbol.toUpperCase()} = {payoutScheme}
    </span>
  ) :symbol === "RXD" ? (
    <span>
      {symbol.toUpperCase()} = {payoutScheme}
    </span>
  ) : symbol === "KAS" ? (
    <span>
      {symbol.toUpperCase()} = {payoutSchemeKas}
    </span> 
  ) : symbol === "KLS" ? (
    <span>
      {symbol.toUpperCase()} = {payoutScheme}
      </span> 
      )
    : symbol === "NEXA" ? (
      <span>
        {symbol.toUpperCase()} = {payoutSchemeKas}
      </span> 
      ) : (
    <span>{symbol.toUpperCase()}</span>
  )}
</div>
          </div>
          <p>{hashHate}</p>
        </div>
        <ul>
          <InfoPoolsLi
            activeMiners={activeMiners}
            luck={luck}
            fee={fee}
            interval={interval}
            minPayment={minPayment}
            symbol={symbol}
            from={coin.from}
          />
        </ul>
      </HashLink>
    </LiPools>
  );
};
