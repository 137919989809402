import { FaSearchDollar as Search } from "react-icons/fa";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Form } from "./minerSearchStyle";
import { useTranslation } from "react-i18next";

export const MinerSearch = ({ name }) => {
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (search === "") {
      toast.error("Insira uma carteira válida");
    } else {
      navigate(`/${name}/wallet/${search.trim()}`);
    }
  };

  return (
    <Form onSubmit={(e) => handleSubmit(e)}>
      <input
        type="text"
        placeholder={t("dash.main.placeholder")}
        onChange={(e) => setSearch(e.target.value)}
      />
      <button>
        <Search />
      </button>
    </Form>
  );
};
