import { useEffect, useState } from "react";
import { databaseServer } from "../../../../services/server/dataBase";
import { databaseServerEn } from "../../../../services/server/dataBaseEn";
import { CardInfoServer } from "../../../../components/CardInfoServer/cardInfoServer";
import {
  DetailsContainer,
  ServerContainer,
  //ServerLi,
  //ServerUl,
} from "./sectionServerInfoStyle";
//import Logo from "../../../../assets/logo.png";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext } from "../../../../contexts/config";

export const SectionServerInfo = () => {
  const [poolsInfoServer, setPoolsInfoServe] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [callCard, setCallCard] = useState("");
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  const { language } = useContext(ConfigContext);

  const getPoolsServer = () => {
    const poolsServe =
      language === "ptBR"
        ? databaseServer.map((moeda) => moeda)
        : databaseServerEn.map((moeda) => moeda);

    setPoolsInfoServe(poolsServe);
  };

  useEffect(() => {
    getPoolsServer();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const infoServerPool = poolsInfoServer.find(
    (infoServer) => infoServer.moeda === callCard
  );

  return (
    <ServerContainer>
 {/*      <ServerUl>
        {poolsInfoServer.map((moeda, i) => (
          <ServerLi key={i} onClick={() => setCallCard(moeda.moeda)}>
            <img id="coinServer" src={moeda.img} alt="criptmoeda" />
            {moeda.moeda}
          </ServerLi>
        ))}
      </ServerUl> */}
      <DetailsContainer callCard={callCard}>
        {!callCard ? (
          <>
            {/* <p className="callAction">
              {t("landingPage.section_footer.choose_your_coin")}
            </p> */}
           {/*  <img className="coinLogo" src={Logo} alt="logo do ThreePool.Tech" /> */}
          </>
        ) : (
          <CardInfoServer
            descricao={infoServerPool.descricao.descricao}
            exemplo={infoServerPool.descricao.exemplo}
            local={infoServerPool.local}
            
          />
        )}
      </DetailsContainer>
    </ServerContainer>
  );
};
