import { Routes, Route } from "react-router-dom";
import { LandingPage } from "../pages/LandingPage/landingPage";
import { NotFoundPage } from "../pages/NotFoundPage/notFoundPage";
import { DashboardPool } from "../pages/DashboardPool/dashboardPool";
import { DashboardMiner } from "../pages/DashboardMiner/dashboardMiner";
import { LandingPageProvider } from "../contexts/landingPage";
import { PoolDashboardProvider } from "../contexts/poolDashboard";

export const MainRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <LandingPageProvider>
            <LandingPage />
          </LandingPageProvider>
        }
      />
      <Route
        path="/dashboard/:name"
        element={
          <PoolDashboardProvider>
            <DashboardPool />
          </PoolDashboardProvider>
        }
      />
      <Route path="/:name/wallet/:address" element={<DashboardMiner />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
