import styled from "styled-components";

export const CardContainer = styled.div`
  flex: 1 1 calc(100% - 16px); 
  min-width: 150px; 
  max-width: calc(100% - 16px);
  height: 80px;
  background-color: #fff; 
  border-radius: 6px; 
  padding: 16px; 
  box-shadow: 0 4px 8px var(--shadow-dash-pool); 
  background-color: var(--color-bg-dashboard-pool-header);

  @media(min-width: 420px){
     flex: 1 1 calc(25% - 16px);
     max-width: calc(20% - 16px);
     min-width: 160px;
  }
`

export const TitleCard = styled.h3`
     display: flex;
     align-items: center;
     gap: 8px;
     
     margin: 2px 0px 8px 0px; 
     font-size: 16px;
     font-weight: 600;

     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     
     color: var(--color-text-cardInfo);

     svg{
          font-size: 16px;
          color: var(--color-text-cardIndo-values);
          font-weight: 600;
     }
`

export const ValueCard = styled.p`
     color: var(--color-text-cardIndo-values);
`