import { ModalStyle } from "./modalStyle";
import { databaseServer } from "../../../services/server/dataBase";
import { databaseServerEn } from "../../../services/server/dataBaseEn";
import { useContext } from "react";
import { ConfigContext } from "../../../contexts/config";
import { CardInfoServer } from "../../../components/CardInfoServer/cardInfoServer";
import { PoolDashboardContext } from "../../../contexts/poolDashboard";

export const Modal = ({ pool }) => {
  const { language } = useContext(ConfigContext);
  const { setViewModal } = useContext(PoolDashboardContext);

  const getServerPool = () => {
    if (language === "en") {
      const poolInfo = databaseServerEn.find(
        (elt) => elt.moeda.toLowerCase() === pool
      );

      return poolInfo ? (
        <ModalStyle>
          <button onClick={() => setViewModal(false)}>X</button>
          <div className="container">
            <CardInfoServer
              descricao={poolInfo.descricao.descricao}
              exemplo={poolInfo.descricao.exemplo}
              local={poolInfo.local}
            />
          </div>
        </ModalStyle>
      ) : (
        <ModalStyle>
          <button onClick={() => setViewModal(false)}>X</button>
          <div className="container">
            <h2>There is no connection information!</h2>
          </div>
        </ModalStyle>
      );
    } else {
      const poolInfo = databaseServer.find(
        (elt) => elt.moeda.toLowerCase() === pool
      );

      return poolInfo ? (
        <ModalStyle>
          <button onClick={() => setViewModal(false)}>X</button>
          <div className="container">
            <CardInfoServer
              descricao={poolInfo.descricao.descricao}
              exemplo={poolInfo.descricao.exemplo}
              local={poolInfo.local}
            />
          </div>
        </ModalStyle>
      ) : (
        <ModalStyle>
          <button onClick={() => setViewModal(false)}>X</button>
          <div className="container">
            <h2>Não foram encontradas informações de conexão!</h2>
          </div>
        </ModalStyle>
      );
    }
  };

  return getServerPool();
};
