// Função para pegar um dado e transformar em uma string HASHRATE.
export const convertHashrate = (hashrate, variant = false) => {
  let metric, adjustedRate, value;

  value = hashrate;

  if (variant) {
    value = hashrate * 300000;
  }

  if (value < 1000) {
    metric = "H/s";
    adjustedRate = value;
  } else if (value >= 1000 && value < 1000 * 1000) {
    adjustedRate = value / 1000;
    metric = "Kh/s";
  } else if (value >= 1000 * 1000 && value < 1000 * 1000 * 1000) {
    adjustedRate = value / (1000 * 1000);
    metric = "Mh/s";
  } else if (value >= 1000 * 1000 * 1000 && value < 1000 * 1000 * 1000 * 1000) {
    adjustedRate = value / (1000 * 1000 * 1000);
    metric = "Gh/s";
  } else if (
    value >= 1000 * 1000 * 1000 &&
    value < 1000 * 1000 * 1000 * 1000 * 1000
  ) {
    adjustedRate = value / (1000 * 1000 * 1000 * 1000);
    metric = "Th/s";
  } else if (value >= 1000 * 1000 * 1000 * 1000 * 1000) {
    adjustedRate = value / (1000 * 1000 * 1000 * 1000 * 1000);
    metric = "Ph/s";
  }

  const calc = Math.round(adjustedRate * 100) / 100;

  const res =
    `${calc} ${metric}` === "NaN undefined" ? "0 H/s" : `${calc} ${metric}`;

  return res;
};

// Função para conversão de data.
export const convertDate = (date, from = "foundation") => {
  let newDate;

  if (from === "dynex" || from === "chinet" || from === "novasol") {
    newDate = new Date(+date * 1000);
  } else {
    newDate = new Date(date);
  }

  let res = newDate.toLocaleDateString(navigator.language, {
    weekday: "long",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  const arr = res.split("");

  const foundComma = arr.findIndex((elem) => elem === ",");

  return res.slice(foundComma + 1);
};

// Função que formata números grandes
export const formatLargeNumbers = (number) => {
  if (number < 1000) {
    return `${number / 1000}H`;
  } else if (number >= 1000 && number < 1000 * 1000) {
    return `${(number / 1000).toFixed(2)}K`;
  } else if (number >= 1000 * 1000 && number < 1000 * 1000 * 1000) {
    return `${(number / (1000 * 1000)).toFixed(2)}M`;
  } else if (
    number >= 1000 * 1000 * 1000 &&
    number < 1000 * 1000 * 1000 * 1000
  ) {
    return `${(number / (1000 * 1000 * 1000)).toFixed(2)}Gh`;
  } else if (
    number >= 1000 * 1000 * 1000 &&
    number < 1000 * 1000 * 1000 * 1000 * 1000
  ) {
    return `${(number / (1000 * 1000 * 1000 * 1000)).toFixed(2)}Th`;
  } else if (number >= 1000 * 1000 * 1000 * 1000 * 1000) {
    return `${(number / (1000 * 1000 * 1000 * 1000 * 1000)).toFixed(2)}Ph`;
  }
};

// Função que pega a divisão correta pro hashrate dos charts
export const getHashrateDivisor = (hashrate) => {
  if (hashrate < 1000) {
    return 1;
  } else if (hashrate >= 1000 && hashrate < 1000 * 1000) {
    return 1000;
  } else if (hashrate >= 1000 * 1000 && hashrate < 1000 * 1000 * 1000) {
    return 1000 * 1000;
  } else if (
    hashrate >= 1000 * 1000 * 1000 &&
    hashrate < 1000 * 1000 * 1000 * 1000
  ) {
    return 1000 * 1000 * 1000;
  } else if (
    hashrate >= 1000 * 1000 * 1000 &&
    hashrate < 1000 * 1000 * 1000 * 1000 * 1000
  ) {
    return 1000 * 1000 * 1000 * 1000;
  } else if (hashrate >= 1000 * 1000 * 1000 * 1000 * 1000) {
    return 1000 * 1000 * 1000 * 1000 * 1000;
  }
};

// Função que calcula a sorte da Dvandal.
export const getLuck = (shares, difficulty) => {
  return Math.round((shares / difficulty) * 100);
};

// Função de converter blocos da Dynex e Chinet.
export const createDvandalBlock = (blocks, from) => {
  const confirmedBlocks = [];
  const pendingBlocks = [];
  const luck1 = [];
  const luck10 = [];
  const luck100 = [];

  for (let i = 0; i < blocks.length; i += 2) {
    const block = blocks[i];

    if (block[0] === "solo" || block[0] === "prop") {
      const luck = getLuck(+block[5], +block[4]);

      if (luck1.length === 0) luck1.push(luck);
      if (luck10.length <= 10) luck10.push(luck);
      if (luck100.length <= 100) luck100.push(luck);

      if (block[7]) {
        if (block[6] === "0") {
          confirmedBlocks.push({
            height: blocks[i + 1],
            date: convertDate(+block[3], "dynex"),
            // type: block[6] === "0" ? "confirmed" : "orphaned",
            reward:
              from === "dynex"
                ? (+block[7] / 1000000000).toFixed(2)
                : (+block[7] / 10000000000).toFixed(2),
            luck: luck + "%",
            progress: null,
            link: block[2],
            hash: block[2],
            wallet: block[1],
            difficulty: +block[4],
          });
        }
      } else {
        if (block[6] === "0") {
          pendingBlocks.push({
            height: blocks[i + 1],
            date: convertDate(+block[3], "dynex"),
            // type: block[6] === "0" ? "confirmed" : "orphaned",
            reward: "Waiting...",
            luck: luck + "%",
            progress: null,
            link: block[2],
            hash: block[2],
            wallet: block[1],
            difficulty: +block[4],
          });
        }
      }
    } else {
      const luck = getLuck(+block[3], +block[2]);

      if (luck1.length === 0) luck1.push(luck);
      if (luck10.length <= 10) luck10.push(luck);
      if (luck100.length <= 100) luck100.push(luck);

      if (block[4] === "0") {
        confirmedBlocks.push({
          height: blocks[i + 1],
          date: convertDate(block[1], "dynex"),
          // type: block[4] === "0" ? "confirmed" : "orphaned",
          reward:
            from === "dynex"
              ? (+block[5] / 1000000000).toFixed(2)
              : (+block[5] / 10000000000).toFixed(2),
          luck: luck + "%",
          progress: null,
          link: block[0],
          hash: block[0],
          wallet: "",
          difficulty: +block[2],
        });
      }
    }
  }

  return [confirmedBlocks, pendingBlocks, luck1, luck10, luck100];
};

// Função de converter blocos da NovaSol.
export const createXnsBlock = (blocks, from) => {
  const confirmedBlocks = [];
  const pendingBlocks = [];
  const luck1 = [];
  const luck10 = [];
  const luck100 = [];

  for (let i = 0; i < blocks.length; i += 2) {
    const block = blocks[i];

    if (block[0] === "solo" || block[0] === "prop") {
      const luck = getLuck(+block[5], +block[4]);

      if (luck1.length === 0) luck1.push(luck);
      if (luck10.length <= 10) luck10.push(luck);
      if (luck100.length <= 100) luck100.push(luck);

      if (block[7]) {
        if (block[6] === "0") {
          confirmedBlocks.push({
            height: blocks[i + 1],
            date: convertDate(+block[3], "novasol"),
            // type: block[6] === "0" ? "confirmed" : "orphaned",
            reward:
              from === "novasol"
                ? (+block[7] / 100000000).toFixed(2)
                : (+block[7] / 100000000).toFixed(2),
            luck: luck + "%",
            progress: null,
            link: block[2],
            hash: block[2],
            wallet: block[1],
            difficulty: +block[4],
          });
        }
      } else {
        if (block[6] === "0") {
          pendingBlocks.push({
            height: blocks[i + 1],
            date: convertDate(+block[3], "novasol"),
            // type: block[6] === "0" ? "confirmed" : "orphaned",
            reward: "Waiting...",
            luck: luck + "%",
            progress: null,
            link: block[2],
            hash: block[2],
            wallet: block[1],
            difficulty: +block[4],
          });
        }
      }
    } else {
      const luck = getLuck(+block[3], +block[2]);

      if (luck1.length === 0) luck1.push(luck);
      if (luck10.length <= 10) luck10.push(luck);
      if (luck100.length <= 100) luck100.push(luck);

      if (block[4] === "0") {
        confirmedBlocks.push({
          height: blocks[i + 1],
          date: convertDate(block[1], "novasol"),
          // type: block[4] === "0" ? "confirmed" : "orphaned",
          reward:
            from === "novasol"
              ? (+block[5] / 100000000).toFixed(2)
              : (+block[5] / 100000000).toFixed(2),
          luck: luck + "%",
          progress: null,
          link: block[0],
          hash: block[0],
          wallet: "",
          difficulty: +block[2],
        });
      }
    }
  }

  return [confirmedBlocks, pendingBlocks, luck1, luck10, luck100];
};

// Função de converter pagamentos da Dynex e Chinet.
export const createDvandalPayments = (payments, symbol) => {
  const formatedPayment = [];

  for (let i = 0; i < payments.length; i += 2) {
    const payment = payments[i].split(":");

    formatedPayment.push({
      address: payment[0],
      reward: (payment[1].replace(/[0.]+$/, "") / 1000).toFixed(2) + symbol,
    });
  }

  return formatedPayment;
};
// Função de converter pagamentos da NovaSol.
export const createXnsPayments = (payments, symbol) => {
  const formatedPayment = [];

  for (let i = 0; i < payments.length; i += 2) {
    const payment = payments[i].split(":");

    formatedPayment.push({
      address: payment[0],
      reward: (payment[1].replace(/[0.]+$/, "") / 100).toFixed(2) + symbol,
    });
  }

  return formatedPayment;
};

// Função de converter mineradores da Dvandal.
export const createDvandalMiners = (miners) => {
  const formatedMiners = [];

  miners.forEach((miner) => {
    formatedMiners.push({
      miner,
    });
  });

  return formatedMiners;
};
