import styled from "styled-components";

export const ContainerInfo = styled.div`
  background: linear-gradient(
    180deg,
    #141522 5.73%,
    rgba(20, 21, 34, 0.69) 53.65%,
    rgba(29, 29, 29, 0.19) 100%
  );
  height: 80vh;
  max-height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;

  @media (min-width: 767px) {
    & {
      height: 50vh;
    }
  }
`;

export const SectionCardInfo = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 50vh;
  max-height: 100%;
  padding: 16px;

  .cardInfo {
    width: 100%;
    max-width: 500px;
    min-height: 50%;
    background-color: var(--brand-2);
    transition: 0.3s;
    transform: translateY(-60%);
    border-radius: 6px;

    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    position: relative;

    box-shadow: 0px 0px 9px 0px #ffffff;

    h3,
    p,
    span {
      color: #ffffff;
      font: var(--text-cardInfo);
    }

    h3 {
      color: var(--brand-1);
      font-weight: var(--font-weigth-1);
    }

    span {
      color: var(--brand-1);
      font-weight: var(--font-weigth-4);
    }

    .title {
      font-weight: var(--font-weigth-1);
    }

    p {
      text-align: center;
      line-height: 26px;
    }

    svg {
      font-size: 40px;
      position: absolute;
      transform: rotate(90deg);
      top: -21px;
      left: calc(50% - 20px);
      color: var(--brand-1);
      animation: moveArrow 2.1s infinite ease-in-out;
    }

    @keyframes moveArrow {
      0% {
        opacity: 0;
        top: -21px;
      }
      50% {
        opacity: 1;
        top: 0px;
      }
      100% {
        opacity: 0;
        top: -21px;
      }
    }
  }

  .cardInfo:hover,
  .cardInfo:active,
  .cardInfo:focus {
    background-color: var(--brand-1);
    color: #171717;
    transition: 0.3s;
    box-shadow: 0px 1px 9px 5px #006666;

    h3 {
      color: #171717;
    }

    span {
      color: #171717;
      font-weight: var(--font-weigth-2);
    }
  }

  @media (min-width: 767px) {
    & {
      flex-direction: row;
      gap: 12px;

      .cardInfo {
        width: 100%;
        max-width: 350px;
        height: 130px;
        transform: translateY(-100%);

        svg {
          transform: rotate(0deg);
          top: calc(50% - 20px);
          left: -28px;
        }

        @keyframes moveArrow {
          0% {
            opacity: 0;
            left: -28px;
          }
          50% {
            opacity: 1;
            left: -20px;
          }
          100% {
            opacity: 0;
            left: -28px;
          }
        }
      }
    }
  }
`;

export const SectionSubTitle = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 16px;
  padding: 16px;

  h2 {
    font: 700 30px var(--font-text);
    color: #ffffff;
  }

  p {
    font: 500 18px var(--font-text);
    color: #ffffff;

    span {
      color: var(--brand-1);
    }
  }

  @media (min-width: 767px) {
    & {
      transform: translateY(-110px);

      h2 {
        font: 700 50px var(--font-text);
      }

      p {
        font: 500 20px var(--font-text);
      }
    }
  }
`;
