import styled from "styled-components";
import "animate.css";

export const ServerContainer = styled.section`
  width: 100vw;
  max-width: 1200px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ServerUl = styled.ul`
  padding: 16px 0;
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  margin: 0 auto;

  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--brand-dashboard);
    border-radius: 4px;
  }
`;

export const ServerLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 40px;
  background-color: var(--brand-dashboard);
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  box-shadow: 2px 3px 4px 1px var(--gray-3);
  color: var(--gray-0);
  font-weight: var(--font-weigth-2);
  gap: 8px;

  &:active,
  &:hover {
    filter: brightness(1.2);
    transition: 0.3s ease;
  }

  & > #coinServer {
    width: 25px;
    height: 25px;
  }
`;

export const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: ${({ callCard }) =>
    callCard === "" ? "none" : "2px solid var(--brand-dashboard)"};
  position: relative;

  .callAction {
    padding: 12px 8px;
    border-radius: 4px;
    background-color: var(--brand-1);
    color: #0e0e0e;
    font-size: var(--f-size-2-title);
    font-weight: bold;
    text-align: center;
  }

  .coinLogo {
    margin-top: 40px;
    width: 80px;
    height: 80px;

    animation: flip 2s infinite ease-in-out;
  }
`;
