import { ConfigProvider } from "./contexts/config";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainRoutes } from "./routes/mainRoutes";
import { GlobalStyle } from "./styles/globalStyles";

export const App = () => {
  return (
    <ConfigProvider>
      <GlobalStyle />
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        theme="colored"
      />
      <MainRoutes />
    </ConfigProvider>
  );
};
