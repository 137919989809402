import { PaymentsContainer, PaymentCell } from "./paymentsSectionStyle";
import { useTranslation } from "react-i18next";

export const PaymentsSection = ({ from, payments }) => {
  const { t } = useTranslation();

  return (
    <PaymentsContainer>
      <div id="tagTitle">
        <p>
          {from === "dynex" || from === "chinet" || from === "novasol"
            ? "TxId"
            : t("dash.payments.wallet")}
        </p>
        <p>{t("dash.payments.reward")}</p>
      </div>
      <ul>
        {payments?.length ? (
          payments.map((elt, i) => (
            <PaymentCell key={i}>
              <li>
                <p>
                  <span>
                    {from === "dynex" || from === "chinet" || from === "novasol"
                      ? "TxId"
                      : t("dash.payments.wallet")}
                    :{" "}
                  </span>
                  {elt.address}
                </p>
                <p>
                  <span>{t("dash.payments.reward")}:</span> {elt.reward}
                </p>
              </li>
            </PaymentCell>
          ))
        ) : (
          <h3>{t("dash.payments.warning")}</h3>
        )}
      </ul>
    </PaymentsContainer>
  );
};
