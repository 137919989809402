import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Content = styled.details`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > #title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: fit-content;
  }
`;

export const Title = styled.h4`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  min-width: 301px;

  & > svg {
    color: var(--brand-dashboard);
    min-width: 20px;
  }
`;

export const Bar = styled.div`
  width: 65px;
  height: 3px;
  background-color: var(--brand-dashboard);
  transition: 0.4s;
`;

export const Text = styled.p`
  font-size: var(--f-size-3);
  line-height: 30px;
  width: 95%;
  font-weight: var(--font-weigth-2);

  @media (min-width: 768px) {
    width: 70%;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > a {
    margin-top: 8px;
    color: var(--gray-6);
    font-size: var(--f-size-2);
    text-decoration: underline;
  }
`;
