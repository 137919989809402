/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { databaseImg } from "../../../../services/imgDB/imgDB";
import { BsArrowLeft, BsWallet2 } from "react-icons/bs";
import { BsGraphUp as HashIcon } from "react-icons/bs";
import { IoPieChartOutline as HashDiary } from "react-icons/io5";
import { AiOutlineTeam as OnlineIcon } from "react-icons/ai";
import { MdPayments as PaymentIcon } from "react-icons/md";
import Logo from "../../../../assets/logo.png";

import { miner } from "../../../../services/Pools/connection";

import {
    CardsSection,
    Container,
    HeaderSection,
    Wrapper,
    InfoSections,
    ContainerLoading,
} from "./minerStyle";

import { HeaderPool } from "../../../../pages/DashboardPool/HeaderPool/headerPool";
import { WorkerSection } from "../../../../pages/DashboardMiner/WorkerSection/workerSection";
import { PropagateLoader, PulseLoader } from "react-spinners";
import { CardDash } from "../CardDash/cardDash";

export const XdagMiner = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [minerData, setMinerData] = useState([]);

    const navigate = useNavigate();

    const { t } = useTranslation();
    const { name, address } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            await miner(name, decodeURIComponent(address)).then((res) => {
                setMinerData(res);
                setIsLoading(false);
            });
        };
        fetchData();
    }, []);


    const criptoData = databaseImg.find((elt) => {
        return elt.nome.toLowerCase() === name.toLowerCase();
    });

    return (
        <Wrapper>
            {isLoading && (
                <ContainerLoading>
                    <PropagateLoader color="#006666" />
                    <h1>
                        {t("dash.main.loading")}{" "}
                        <PulseLoader color="var(--gray-6)" margin={3} size={4} />
                    </h1>
                </ContainerLoading>
            )}
            <HeaderPool />
            <Container>
                <HeaderSection from={"xdag"}>
                    <button
                        onClick={() => {
                            navigate(`/dashboard/${name}/#top`);
                        }}
                    >
                        <span>
                            <BsArrowLeft />
                            {t("miner_dash.back")}
                            <img
                                src={criptoData !== undefined ? criptoData.img : Logo}
                                alt="Cripto Logo"
                            />
                        </span>
                        <div />
                    </button>
                    <div>
                        <span>
                            <h2>
                                <BsWallet2 />
                                {t("miner_dash.wallet")}
                            </h2>
                            <p>{decodeURIComponent(address)}</p>
                        </span>
                    </div>
                </HeaderSection>
                <CardsSection from={"xdag"}> 
                    <CardDash Icon={<HashIcon />} title="Hashrate" value={minerData?.headers?.hashrate} />
                    <CardDash Icon={<HashDiary />} title={ t("miner_dash.cards.today_hashrate")} value={minerData?.headers?.todayHashrate} />
                    <CardDash Icon={<OnlineIcon />} title={t("miner_dash.cards.online_workers")} value={minerData?.headers?.workersOnline}/>
                    <CardDash Icon={<PaymentIcon />} title={t("miner_dash.cards.payments")} value={minerData?.headers?.payment}/>
                </CardsSection>
                <InfoSections>
                    <WorkerSection
                        workers={minerData?.workers}
                        from={"xdag"}
                    />
                </InfoSections>
            </Container>
        </Wrapper>
    );
};
