import ApexChart from "react-apexcharts";
import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../contexts/config";
import { useTranslation } from "react-i18next";

export const ChartInfo = ({ dataChart }) => {
  const [chartWidth, setChartWidth] = useState(undefined);

  const { darkMode } = useContext(ConfigContext);

  const { t } = useTranslation();

  const dataHash = [];
  const dataDate = [];

  let aux = new Date(dataChart[0].date).getHours();

  dataChart.forEach((elt) => {
    const date = isNaN(+elt.date) ? new Date(elt.date) : new Date(+elt.date);

    const hour = date.getHours();

    if (
      elt.from === "foundationV2" ||
      elt.from === "foundationV1" ||
      elt.from === "dynex" ||
      elt.from === "chinet" ||
      elt.from === "novasol"
    ) {
      if (hour !== aux) {
        dataHash.push(elt.hashrate);
        dataDate.push(`${hour}h`);
      }
      aux = hour;
    } else {
      dataHash.push(elt.hashrate);
      dataDate.push(`${hour}h`);
    }
  });

  const color = darkMode ? "#006666" : "#00cccc";
  const toColor = darkMode ? "#1b1a1a" : "#F9F9F9";
  const labelColor = darkMode ? "#ECECEC" : "#000000";

  const series = [
    {
      name: "Hashrate",
      data: dataHash,
    },
  ];

  const options = {
    charts: {
      type: "area",
      sparkline: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
        autoSelected: "zoom",
      },
    },
    colors: [color],
    title: {
      text: t("dash.main.cards.daily_hash"),
      style: {
        color,
        fontFamily: "Roboto",
        fontSize: "16px",
      },
      align: "left",
    },
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    markers: {
      show: false,
      shape: "circle",
      strokeWidth: 0,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: darkMode ? 0.9 : 0.7,
        opacityTo: darkMode ? 0.7 : 0.9,
        gradientToColors: [toColor],
        stops: [0, 100],
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontFamily: "Roboto",
          fontSize: "11px",
          fontWeight: 100,
        },
      },
    },
    xaxis: {
      categories: dataDate,
      labels: {
        style: {
          colors: labelColor,
          fontFamily: "Roboto",
          fontSize: "0px",
          fontWeight: 100,
        },
      },
    },
  };

  useEffect(() => {
    const container = document.querySelector(".grafico");
    const handleResize = () => {
      setChartWidth(container.offsetWidth);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ApexChart
      options={options}
      series={series}
      type="area"
      width={chartWidth}
      height={"100%"}
    />
  );
};
