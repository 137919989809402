//import { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MinersContainer, MinerCell } from "./minersSectionStyle";
import { useTranslation } from "react-i18next";

export const MinerSection = ({ miners }) => {
  const navigate = useNavigate();

  const { name } = useParams();

  const { t } = useTranslation();

  return (
    <MinersContainer>
      <div id="tagTitle">
        <p>{t("dash.miners.wallet")}</p>
        <p>Hashrate</p>
      </div>
      <ul>
        {miners?.length ? (
          miners?.map((elt, i) => (
            <MinerCell
              disabled={name === "dynex" || name === "chinet" || name === "novasol"}
              key={i}
              onClick={() => navigate(`/${name}/wallet/${encodeURIComponent(elt.wallet)}`)}
            >
              <li>
                <p>
                  <span>{t("dash.miners.wallet")}: </span>
                  {elt.wallet}
                </p>
                <p>
                  <span>Hashrate:</span> {elt.hashrate}
                </p>
              </li>
            </MinerCell>
          ))
        ) : (
          <h3>{t("dash.miners.warning")}</h3>
        )}
      </ul>
    </MinersContainer>
  );
};
