import { useTranslation } from "react-i18next";
import { HeaderPool } from "../../../../pages/DashboardPool/HeaderPool/headerPool"
import { SubHeaderXdag } from "../SubHeader/subHeaderXdag"
import { Container, DataContainer, SectionCard, MinersContainer, MinerCell, Main } from "./dashboardStyle"
import { ContainerLoading } from "../../../../pages/DashboardPool/dashboardPoolStyle";
import { PropagateLoader, PulseLoader } from "react-spinners";
import { Modal } from "../../../../pages/DashboardPool/Modal/modal";
import { PoolDashboardContext } from "../../../../contexts/poolDashboard";
import { useContext } from "react";
import { CardDash } from "../CardDash/cardDash";
import { GiMiner as WorkerIcon } from "react-icons/gi";
import { RiPercentLine as FeeIcon } from "react-icons/ri";
import { BsGraphUp as HashIcon } from "react-icons/bs";
import { TbArrowBackUp as Back } from "react-icons/tb";
import { AiOutlineTeam as OnlineIcon } from "react-icons/ai";
import { ConfigContext } from "../../../../contexts/config";
import { FAQContainer } from "../../../FAQContainer/faqContainer";
import { useNavigate } from "react-router-dom";
import { Footer } from "../../../Footer/footer";

export const XdagDashboard = (poolData) => {
    const { viewFaq, handleSelected } = useContext(ConfigContext);
    const { viewModal } = useContext(PoolDashboardContext);

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <Main>
            <Container>
                {!poolData ? (
                    <ContainerLoading>
                        <PropagateLoader color="#00cccc" />
                        <h1>
                            {t("dash.main.loading")}{" "}
                            <PulseLoader color="var(--gray-6)" margin={3} size={4} />
                        </h1>
                    </ContainerLoading>
                ) : (
                    <Container>
                        <HeaderPool />
                        <SubHeaderXdag name="Xdag" />
                        <DataContainer>
                            {viewModal && <Modal pool="xdag" />}
                            {viewFaq ? (
                                <div className="faq">
                                    <button onClick={() => handleSelected()}>
                                        {t("miner_dash.back")}
                                        <Back />
                                    </button>
                                    <FAQContainer />
                                </div>
                            ) : (
                                <>
                                    <SectionCard className="container">
                                        <CardDash Icon={<FeeIcon />} title={t("dash.main.cards.fee")} value={poolData.headers.fee} />
                                        <CardDash Icon={<HashIcon />} title={"Pool Hashrate"} value={poolData.headers.hashrate} />
                                        <CardDash Icon={<HashIcon />} title={t("dash.main.cards.algorithm")} value={poolData.headers.algorithm} />
                                      {/*   <CardDash Icon={<WorkerIcon />} title={t("dash.main.cards.total_workers")} value={poolData.headers.totalWorkers} /> */}
                                        <CardDash Icon={<WorkerIcon />} title={t("dash.main.cards.total_miners")} value={poolData.headers.totalMiners} />
                                        <CardDash Icon={<OnlineIcon />} title={t("dash.main.cards.online_workers")} value={poolData.headers.activeWorkers} />
                                    </SectionCard>

                                    <MinersContainer>
                                        <div id="tagTitle">
                                            <p>{t("dash.miners.wallet")}</p>
                                            <p>Hashrate</p>
                                        </div>
                                        {poolData.miners.length ? (
                                            poolData.miners.map((elt, i) => (
                                                <MinerCell
                                                    key={i}
                                                    onClick={() => navigate(`/xdag/wallet/${encodeURIComponent(elt.wallet)}`)}
                                                >
                                                    <li>
                                                        <p>
                                                            <span>{t("dash.miners.wallet")}: </span>
                                                            {elt.wallet}
                                                        </p>
                                                        <p>
                                                            <span>Hashrate:</span> {elt.hashrate}
                                                        </p>
                                                    </li>
                                                </MinerCell>
                                            ))
                                        ) : (
                                            <h3>{t("dash.miners.warning")}</h3>
                                        )}
                                    </MinersContainer>
                                </>
                            )}
                        </DataContainer>
                    </Container>
                )}
            </Container>
            <Footer />
        </Main>
    )
}
