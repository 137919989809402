/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../contexts/config";
import { useTranslation } from "react-i18next";
import { databaseImg } from "../../services/imgDB/imgDB";
import { AiOutlineSearch } from "react-icons/ai";
import { BsArrowLeft, BsWallet2 } from "react-icons/bs";
import { MdPayment } from "react-icons/md";
import Logo from "../../assets/logo.png";

import { miner } from "../../services/Pools/connection";

import {
  CardsSection,
  Container,
  HeaderSection,
  Wrapper,
  InfoSections,
  ContainerLoading,
} from "./dashboardMinerStyle";

import { HeaderPool } from "../DashboardPool/HeaderPool/headerPool";
import { CardInfo } from "../DashboardPool/CardInfo/cardInfo";
import { WorkerSection } from "./WorkerSection/workerSection";
import { PaymentsSection } from "./PaymentsSection/paymentSection";
import { PropagateLoader, PulseLoader } from "react-spinners";
import { needCustomDashboard } from "../../services/Pools/custom";

export const DashboardMiner = () => {
  const [changeSections, setChangeSections] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [minerData, setMinerData] = useState([]);

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { darkMode } = useContext(ConfigContext);
  const { name, address } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await miner(name, decodeURIComponent(address)).then((res) => {
        setMinerData(res);
        setIsLoading(false);
      });
    };
    fetchData();
  }, []);

  const findCustomMiner = needCustomDashboard.find((elt) => elt.coin.toLowerCase() === name.toLowerCase())

  if (findCustomMiner) {
    return findCustomMiner.minerPage(address, name, minerData)
  }

  const from = minerData?.from;

  const hashrate = minerData?.headers?.hashrate;
  const work = minerData?.headers?.work;
  const validShares = minerData?.headers?.validShares;
  const effort = minerData?.headers?.effort;
  const sharesPerSecond = minerData?.headers?.sharesPerSecond;
  const pendingShares = minerData?.headers?.pendingShares;
  const lastShare = minerData?.headers?.lastShare;
  const payment = minerData?.headers?.payment;
  const pendingPayment = minerData?.headers?.pendingPayment;
  const todayPayment = minerData?.headers?.todayPayment;
  const pendingPayout = minerData?.headers?.pendingPayout;
  const invalidShares = minerData?.headers?.invalidShares;
  const staleShares = minerData?.headers?.staleShares;

  const firstCardData = {
    icons: [<AiOutlineSearch />, <AiOutlineSearch />, <AiOutlineSearch />],
    texts: [
      "Hashrate",
      from === "foundationV1" || from === "foundationV2"
        ? t("miner_dash.cards.work")
        : t("miner_dash.cards.shares"),
      from === "foundationV1" || from === "foundationV2"
        ? t("miner_dash.cards.pending_bal")
        : from === "miningCore"
          ? t("miner_dash.cards.pending")
          : t("miner_dash.cards.last_shares"),
    ],
    values: [
      hashrate,
      from === "foundationV1"
        ? work
        : from === "foundationV2"
          ? effort
          : sharesPerSecond,
      from === "foundationV1" || from === "foundationV2"
        ? pendingPayout
        : from === "miningCore"
          ? pendingShares
          : lastShare,
    ],
  };

  const secondCardData = {
    icons: [<AiOutlineSearch />, <AiOutlineSearch />, <AiOutlineSearch />],
    texts:
      from === "foundationV1" || from === "foundationV2"
        ? [
          t("miner_dash.cards.valid"),
          t("miner_dash.cards.invalid"),
          t("miner_dash.cards.stale"),
        ]
        : [
          t("miner_dash.cards.paid"),
          t("miner_dash.cards.pending_bal"),
          t("miner_dash.cards.payout"),
        ],
    values:
      from === "foundationV1" || from === "foundationV2"
        ? [validShares, invalidShares, staleShares]
        : [payment, pendingPayment, todayPayment],
  };

  const criptoData = databaseImg.find((elt) => {
    if (name === "flr_solo") {
      if (!darkMode) {
        return elt.nome === "flora_solo_dark";
      }
      return elt.nome === "flora_solo";
    }
    else if (name === "novo_solo") {
      if (!darkMode) {
        return elt.nome === "novo_solo_dark";
      }
      return elt.nome === "novo_solo";
    }

    else if (name === "kaspa_solo") {
      if (!darkMode) {
        return elt.nome === "kaspa_solo_dark";
      }
      return elt.nome === "kaspa_solo";
    }

    if (name === "flora") {
      if (!darkMode) {
        return elt.nome === "flora_dark";
      }
      return elt.nome === "flora";
    }

    return elt.nome.toLowerCase() === name.toLowerCase();
  });

  return (
    <Wrapper>
      {isLoading && (
        <ContainerLoading>
          <PropagateLoader color="#006666" />
          <h1>
            {t("dash.main.loading")}{" "}
            <PulseLoader color="var(--gray-6)" margin={3} size={4} />
          </h1>
        </ContainerLoading>
      )}
      <HeaderPool />
      <Container>
        <HeaderSection from={from}>
          <button
            onClick={() => {
              navigate(`/dashboard/${name}/#top`);
            }}
          >
            <span>
              <BsArrowLeft />
              {t("miner_dash.back")}
              <img
                src={criptoData !== undefined ? criptoData.img : Logo}
                alt="Cripto Logo"
              />
            </span>
            <div />
          </button>
          <div>
            <span>
              <h2>
                <BsWallet2 />
                {t("miner_dash.wallet")}
              </h2>
              <p>{decodeURIComponent(address)}</p>
            </span>
            {(from === "foundationV1" || from === "foundationV2") && (
              <span>
                <h2>
                  <MdPayment />
                  {t("miner_dash.paid")}
                </h2>
                <p>{payment}</p>
              </span>
            )}
          </div>
        </HeaderSection>
        <CardsSection from={from}>
          <CardInfo
            tag={"minerDash"}
            icons={firstCardData.icons}
            texts={firstCardData.texts}
            values={firstCardData.values}
          />
          <CardInfo
            tag={"minerDash"}
            icons={secondCardData.icons}
            texts={secondCardData.texts}
            values={secondCardData.values}
          />
          {from !== "foundationV1" && from !== "foundationV2" && (
            <div>
              <span>
                <button type="button" onClick={() => setChangeSections(true)}>
                  {t("miner_dash.cards.workers")}
                </button>
              </span>
              <span>
                <button type="button" onClick={() => setChangeSections(false)}>
                  {t("miner_dash.cards.payments")}
                </button>
              </span>
            </div>
          )}
        </CardsSection>
        <InfoSections>
          {changeSections ? (
            <WorkerSection
              workers={minerData?.workers}
              from={from}
              setChangeSections={setChangeSections}
            />
          ) : (
            from !== "foundationV1" &&
            from !== "foundationV2" && (
              <PaymentsSection
                payments={minerData?.payments}
                from={from}
                setChangeSections={setChangeSections}
              />
            )
          )}
        </InfoSections>
      </Container>
    </Wrapper>
  );
};
