import { ServerInfoStyle, Status } from "./cardInfoServerStyle";
import { useTranslation } from "react-i18next";

export const CardInfoServer = ({ descricao, exemplo, local }) => {
  const { t } = useTranslation();

  return (
    <ServerInfoStyle>
      <h3>{t("connection_info.conn_details")}</h3>
      <div className="serverInfoContainer">
        <div className="serverInfoContent">
          <div className="nameColumnsInfoServer">
            <ul>
              <li className="columnLocal">{t("connection_info.place")}</li>
              <li className="columnServer">{t("connection_info.server")}</li>
              <li className="columnDificulty">{t("connection_info.dif")}</li>
              <li className="columnStatus">Status</li>
            </ul>
          </div>
          <section>
            {local.map((elt, i) => (
              <div key={i} className="divContentInfoServer">
                <div className="infoServerCountry columnLocal">
                <p>{elt.flag} {elt.pais}</p>
                  <p>{elt.cidade}</p>
                </div>
                <div className="infoServerContent">
                  {elt.server.map((elt, i) => (
                    <div key={i}>
                      <p className="columnServer">
                        {elt.endereco}
                        <br></br>
                        {elt.endereco1}
                      </p>
                      <p className="columnDificulty">{elt.dificuldade}</p>
                      <Status className="columnStatus" status={elt.status}>
                        {elt.status}
                      </Status>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="divContentSeparator" />
          </section>
        </div>
      </div>
      <section className="serverInfoDescription">
        <p>{descricao}</p>
        <p>
          <span>{t("connection_info.ex")}</span> {exemplo}{" "}
        </p>
      </section>
    </ServerInfoStyle>
  );
};
