// import { useContext } from "react";
// import { JoinApisContext } from "../../context/joinApis";
import { BackgroundDiv, InfoNotFound } from "./notFoundPageStyle";
import { GoAlert as Alert } from "react-icons/go";
import { AiFillHome as Home } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { LanguageSwitch } from "../../components/LanguageSwitch/languageSwitch";
import { useTranslation } from "react-i18next";

export const NotFoundPage = () => {
  // const { darkMode } = useContext(JoinApisContext);
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <>
      <BackgroundDiv>
        <h3>404</h3>
        <InfoNotFound>
          <h2>
            Oops <Alert /> <LanguageSwitch />
          </h2>
          <p>
            {t("not_found_page.msg1")} <span>{t("not_found_page.span")}</span>
            {t("not_found_page.msg2")}
          </p>
          <span className="pageNotFound">
            <Alert /> {t("not_found_page.alert_msg")} <Alert />{" "}
          </span>
          <button onClick={() => navigate("/")}>
            {t("not_found_page.back")}
            <Home />
          </button>
        </InfoNotFound>
      </BackgroundDiv>
    </>
  );
};
