import styled from "styled-components";

export const WorkerWapper = styled.section`
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-dashboard-pool-header);
  border-radius: 6px;
`;

export const WorkerTitle = styled.h3`
  padding: 20px 6px 32px 6px;
  text-align: center;
  font-size: 25px;
  font-weight: var(--font-weigth-1);
  color: var(--color-title-coin-dashboard);
`;

export const WorkersChange = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;

  button:first-child {
    border: 1px solid var(--color-text-cardIndo-values);
    color: var(--color-text-cardIndo-values);
    background-color: var(--gray-1);
  }

  button {
    padding: 12px 18px;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: var(--color-text-cardIndo-values);
    color: var(--gray-1);
    font-size: var(--text-2);
    font-weight: bold;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

export const WorkerList = styled.ul`
  padding: 6px;
  display: flex;
  flex-direction: column;

  & > h2 {
    width: 100%;
    text-align: center;
    font-weight: var(--font-weigth-2);
    color: var(--gray-6);
    padding: 4px;
    margin-bottom: 4px;
  }
`;

export const WorkerCard = styled.li`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: var(--gray-4);
  border-top: 1px solid var(--color-border-cardInfo);
  padding: 20px 5px;

  :nth-child(even) {
    background-color: var(--color-bg-menu-mobile-line-even);
  }

  :nth-child(odd) {
    background-color: var(--color-bg-menu-mobile-line-odd);
  }

  & > p,
  & > span {
    letter-spacing: 0.5px;
    font-weight: var(--f-weigth-2);
    margin: 5px;
    display: flex;
    justify-content: space-between;
  }
`;
