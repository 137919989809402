import styled from "styled-components";

export const InfoCard = styled.div`
  width: 100%;
  max-width: 660px;
  min-height: 194px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: var(--color-bg-dashboard-pool-header);
  border-radius: 6px;
  box-shadow: 6px 5px 4px 0px var(--shadow-dash-pool);

  & > span {
    width: 90%;
    padding: 22px 0;
    display: flex;
    justify-content: space-between;
    align-items:center;
    border-bottom: 1px solid var(--color-border-cardInfo);
    margin: 0 auto;
    letter-spacing: 0.5px;
    font-weight: var(--f-weigth-2);

    & > .textValues{
      color: var(--color-text-cardIndo-values);
    }
    & > span {
      display: flex;
      align-items: center;
      gap: 5px;

      & > svg {
        width: 20px;
        height: 20px;
        color: var(--color-icons-cardInfo);
      }

    }
  }

  .noborder {
    border-style: none;
  }

  p {
    color: var(--color-text-cardInfo);
  }

  @media(min-width: 611px){
    &{
      flex-basis: 48%;
      margin-right: 4%;
      
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  @media(min-width: 1000px){
    &{
      width: 320px;
      flex-basis: unset;
      margin-right: unset;
    }
  }
`;

