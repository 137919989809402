import styled, { css } from "styled-components";

export const BackgroundDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--gray-0);
  position: relative;

  h3 {
    font-weight: 900;
    font-size: 30rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--brand-dashboard);
    font-family: "Baloo 2", cursive;
    opacity: 0.5;
    ${(props) =>
      props.dark
        ? css`
            text-shadow: 1px -1px 0 #006666, 2px -2px 0 #006666,
              3px -3px 0 #006666, 4px -4px 0 #006666, 5px -5px 0 #006666,
              6px -6px 0 #006666, 7px -7px 0 #006666, 8px -8px 0 #006666,
              9px -9px 0 #006666, 10px -10px 0 #006666, 11px -11px 0 #006666,
              12px -12px 0 #006666, 13px -13px 12px rgba(0, 0, 0, 0.55),
              13px -13px 1px rgba(0, 0, 0, 0.5);
          `
        : css`
            text-shadow: 1px -1px 0 #2f5d87, 2px -2px 0 #2e5a83,
              3px -3px 0 #2d5880, 4px -4px 0 #2b557c, 5px -5px 0 #2a5378,
              6px -6px 0 #295074, 7px -7px 0 #274d71, 8px -8px 0 #264b6d,
              9px -9px 0 #254869, 10px -10px 0 #234665, 11px -11px 0 #224361,
              12px -12px 0 #21405e, 13px -13px 12px rgba(0, 0, 0, 0.55),
              13px -13px 1px rgba(0, 0, 0, 0.5);
          `};
  }

  @media (max-width: 824px) {
    & {
      h3 {
        font-size: 15rem;
      }
    }
  }

  @media (max-width: 406px) {
    & {
      h3 {
        font-size: 10rem;
      }
    }
  }
`;

export const InfoNotFound = styled.div`
  width: 100%;
  max-width: 600px;
  height: 360px;
  background-color: #f1f1f130;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  h2 {
    font-size: 40px;
    font-weight: 900;
    color: var(--gray-6);
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    & > button {
      background-color: transparent;
      margin-left: 30px;
      font-size: 25px;

      & > img {
        width: 33px;
        height: 33px;
      }
    }

    svg {
      color: ${(props) => (props.dark ? `#006666` : `#e61e1e`)};
    }
  }

  p {
    font: var(--text-notFound);
    color: var(--gray-5);

    span {
      color: ${(props) => (props.dark ? "#006666" : "#185998")};
      font-weight: 800;

      svg {
        color: red;
      }
    }
  }

  .pageNotFound {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 100%;
    background-color: var(--brand-dashboard);
    border-radius: 4px;
    font-size: 18px;
    font-weight: 600;
    color: ${(props) => (props.dark ? `#171717` : `#ECECEC`)};

    svg {
      color: ${(props) => (props.dark ? `#e61e1e` : `#006666`)};
      font-size: 22px;
    }
  }

  button {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: var(--gray-1);
    border-radius: 4px;
    border-style: none;
    color: ${(props) => (props.dark ? `#f1f1f1` : `#171717`)};
    font: var(--text-btn);
    font-size: 16px;
  }
`;
