import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

:root {
    /* Brand Color */
    --brand-1: #00cccc;
    --brand-2: #282f44;
    --brand-dashboard: #185998;

    /* Background-Colors */
    --color-bg-dashboard-pool: #ffffff;
    --color-bg-dashboard-pool-header: #f9f9f9;
    --color-bg-dashboard-menu-mobile: #e8e8e8;
    --color-bg-menu-mobile-line-odd: #dddddd;
    --color-bg-menu-mobile-line-even: #ffffff8a;
    --color-bg-button-subHeader-animation: #009999;
    --color-bg-input-subHeader: #ececec;
    --color-bg-dash-faq: #e9e9e9;
    --card-serverInfo: #009999;
    --card-serverColumns: #d9d9d9;
    --pools-bg: #ffffff;
    --color-bg-bar-progress-1: #3498db;
    --color-bg-bar-progress-2: #0072e0;
    --color-bg-bar: #c0c0c0;

    /* Border-Colors */
    --color-border-bottom-menu: #00cccc;
    --color-border-hover-menu: #00cccc;
    --color-border-button-subHeder: #a5a5a5;
    --color-border-cardInfo: #009999;

    /* Text-Colors */
    --color-text-odd: #00cccc;
    --color-text-even: #009999;
    --color-text-cardInfo: #006666;
    --color-text-cardIndo-values: #006666;
    --color-text-title-faq: #343434;
    --color-text-dash-faq: #21262c;
    --color-title-coin-dashboard: #006666;

    /* Icons-Colors */
    --color-icons-header-desktop: #00cccc;
    --color-icons-cardInfo: #00cccc;

    /* Shadows */
    --color-shadow-header: #00cccc;
    --shadows-input-subHeader: #009999;
    --shadow-dash-pool: #009999;

    /* other colors */
    --color-input-subHeader: #585858;
    --pools-text: #006666;
    --footer-bg: #006666;
    --black: #006666;

    /* Shades of gray */
    --gray-0: #ffffff;
    --gray-1: #ececec;
    --gray-2: #a5a5a5;
    --gray-3: #5e5e5e;
    --gray-4: #343434;
    --gray-5: #171717;
    --gray-6: #21262c;

    /* validar */
    --color-line-odd: #ececec;
    --color-text-odd: #006666;
    --color-line-even: #ffffff;
    --color-line-bottom: #a5a5a542;

    /* Config Fonts */
    --font-text: "Roboto", sans-serif;
    --font-title: "Secular One", sans-serif;

    --font-weigth-1: 700;
    --font-weigth-2: 500;
    --font-weigth-3: 400;
    --font-weigth-4: 300;

    --f-size-1: 20px;
    --f-size-2: 18px;
    --f-size-3: 16px;
    --f-size-4: 14px;
    --f-size-1-title: 32px;
    --f-size-2-title: 24px;
    --f-size-3-title: 22px;

    /* Font-config */
    --text-btn: 500 18px var(--font-text);
    --text-slogan: 400 40px var(--font-title);
    --text-cardInfo: 400 18px var(--font-text);
    --text-subSlogan: 300 25px var(--font-text);
    --text-notFound: 400 22px var(--font-text);

    /* Cards-Colors */
    --pools-bg: #ffffff;
}

:root.dark {
    /* Brand Color */
    --brand-dashboard: #00cccc;

    /* Background-Colors */
    --color-bg-dashboard-pool: #21262c;
    --color-bg-dashboard-pool-header: #1b1a1a;
    --color-bg-dashboard-menu-mobile: #131313;
    --color-bg-menu-mobile-line-odd: #1a1b1d;
    --color-bg-menu-mobile-line-even: #131313;
    --color-bg-button-subHeader-animation: #009999;
    --color-bg-input-subHeader: #1b1a1a;
    --color-bg-dash-faq: #181818;
    --color-bg-bar-progress-1: #009999;
    --color-bg-bar-progress-2: #00cccc;
    --color-bg-bar: #232323;

    --card-serverInfo: #00cccc;
    --card-serverColumns: #000000;

    /* Border-Colors */
    --color-border-bottom-menu: #006666;
    --color-border-hover-menu: #00cccc;
    --color-border-button-subHeder: #343434;
    --color-border-cardInfo: #006666;

    /* Text-Colors */
    --color-text-odd: #00cccc;
    --color-text-even: #d5d5d5;
    --color-text-cardInfo: #ececec;
    --color-text-cardIndo-values: #009999;
    --color-text-title-faq: #d5d5d5;
    --color-text-dash-faq: #c7c7c7;
    --color-title-coin-dashboard: #00cccc;

    /* Icons-Colors */
    --color-icons-header-desktop: #00cccc;
    --color-icons-cardInfo: #009999;

    /* Shadows */
    --color-shadow-header: #5e5e5e;
    --sh: 6px 5px 4px 0px #13181e;
    --shadow-dash-pool: #13181e;
    --shadows-input-subHeader: #1b1a1a;

    /* other colors */
    --color-input-subHeader: #585858;
    --footer-bg: #000000;
    --pools-text: #00cccc;
    --pools-bg: #000000;

    /* Shades of gray */
    --gray-0: #21262c;
    --gray-1: #000000;
    --gray-2: #000000;
    --gray-3: #5e5e5e;
    --gray-4: #a5a5a5;
    --gray-5: #ececec;
    --gray-6: #ffffff;

    /* Validar */
    --color-line-odd: #21262c;
    /* --color-text-odd: #00cccc; */
    --color-line-even: #313131;
    /* --color-text-even: #f1f1f1; */
    --color-line-bottom: #313131;
    /* Cards-Colors */
}

.container{
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
}

html, body, div, span,
h1, h2, h3, h4, h5, h6, p,
a, img, q, s, small, strong,
b, u, i, ol, ul, li, form, label, caption,
article, aside, figure, figcaption, footer, header,
nav, section, main,input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    text-decoration: none;

}
h1, h2, h3, h4, h5,p,span{
    cursor: default;
}

article, aside, figcaption, figure,
footer, header, nav, section {
    display: block;
}
body {
    font-family: var(--font-text);
    line-height: 1;
    overflow-x: hidden;

}
ol, ul {
    list-style: none;
}

input,button{
    border-style: none;
    outline: none;
}

input{
    cursor: text;
}

button, a{
    cursor: pointer;
}
`;
