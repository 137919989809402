import styled from "styled-components";

export const ServerInfoStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--gray-6);

  .serverInfoContainer {
    overflow-x: auto;
  }

  .serverInfoContent {
    min-width: 768px;
  }

  h3 {
    grid-area: h3;
    font-size: var(--f-size-2-title);
    font-weight: var(--font-weigth-1);
    background-color: var(--card-serverInfo);
    width: 100%;
    text-align: center;
    padding: 16px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #000;
  }

  .nameColumnsInfoServer {
    width: 100%;
    background-color: var(--card-serverColumns);
    padding: 0px 16px;

    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 16px;

      & > .columnStatus {
        text-align: right !important;
      }

      li {
        text-overflow: ellipsis;
        overflow: hidden;
        color: var(--gray-6);
        font-weight: var(--font-weigth-2);
      }
    }
  }

  .columnLocal {
    width: 25%;
    text-align: left;
    flex-grow: 2;
  }

  .columnServer {
    width: 45%;
    text-align: left;
    flex-grow: 3;
  }

  .columnDificulty {
    width: 20%;
    text-align: left;
    flex-grow: 1;
  }

  .columnStatus {
    width: 10%;
    text-align: left;
    flex-grow: 1;
  }

  section {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;

    .divContentInfoServer {
      display: flex;
      justify-content: flex-start;
      border-bottom: 2px solid #d9d9d9;
      padding: 16px;

      .infoServerCountry {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 6px;

        p {
        display: flex;
        gap: 8px;
        align-items: center;
        font-weight: var(--font-weigth-1);
          
        }
      }

      .infoServerContent {
        display: flex;
        flex-direction: column;
        width: 75%;

        div {
          display: flex;
          justify-content: flex-start;

          p {
            font-weight: var(--font-weigth-3);
          }
        }
      }
    }

    .divContentSeparator {
      width: 100%;
      height: 12px;
      background-color: var(--brand-dashboard);
    }
  }

  .serverInfoDescription {
    padding: 16px;
    display: flex;
    gap: 12px;
  
    span {
      color: var(--brand-dashboard);
    }

    p {
      word-wrap: break-word;
      white-space: pre-wrap;
      padding: 10px;
      
    }
  }
`;

export const Status = styled.p`
  color: ${({ status }) =>
    status === "Online" ? "#00A507" : "#FF0303"} !important;
  font-weight: var(--font-weigth-2) !important;
  text-align: right !important;
  letter-spacing: 0.5px;
`;
