import styled from "styled-components";

export const BlocksContainer = styled.section`
  width: 100%;
  margin-top: 20px;
  padding: 20px 0px;
  background-color: var(--color-bg-dashboard-pool-header);
  border-radius: 6px;
  box-shadow: var(--sh);

  & > h1 {
    font-size: 25px;
    font-weight: var(--font-weigth-1);
    color: var(--color-title-coin-dashboard);
    text-align: center;
    padding-bottom: 12px;
  }

  #tagTitle {
    background-color: var(--color-title-coin-dashboard);
    border-radius: 16px 16px 0 0;
    font-weight: var(--font-weigth-1);

    & > p {
      color: var(--gray-1);
    }
  }

  h3 {
    padding-top: 20px;
    text-align: center;
    color: var(--gray-5);
    font-weight: bold;
    font-size: var(--text-1);
  }

  & > ul {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;

    & > li {
      width: 133px;
      margin: 16px 0;

      & > button {
        width: 100%;
        padding: 4px;
        background-color: transparent;
        color: var(--gray-6);
        border: 2px solid transparent;
        border-bottom: 2px solid var(--color-border-button-subHeder);
        border-radius: 4px;
        font: var(--text-btn);
        display: block;
      }

      & > button.selected {
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation: button-selected 1s ease-in-out forwards;
      }

      & > button:hover {
        filter: brightness(1.2);
        border-bottom: 2px solid var(--brand-dashboard);
        transition: all 0.2s ease-in;
      }

      @keyframes button-selected {
        0% {
          background-color: transparent;
          border-bottom: 2px solid var(--brand-dashboard);
          border-left: 2px solid transparent;
          border-top: 2px solid transparent;
          border-right: 2px solid transparent;
        }
        25% {
          border-left: 2px solid var(--brand-dashboard);
        }
        50% {
          border-top: 2px solid var(--brand-dashboard);
        }
        98% {
          border-right: 2px solid var(--brand-dashboard);
        }
        100% {
          width: 100%;
          background-color: var(--color-bg-button-subHeader-animation);
          color: var(--gray-0);
          border: 2px solid var(--brand-dashboard);
        }
      }
    }
  }

  @media (max-width: 767px) {
    #tagTitle {
      display: none;
    }
  }
`;

export const InfoCell = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid var(--color-border-cardInfo);
  padding: 20px 10px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  gap: 3px;

  &:nth-child(even) {
    background-color: var(--color-bg-menu-mobile-line-even);
    color: var(--color-text-even);
  }

  &:nth-child(odd) {
    background-color: var(--color-bg-menu-mobile-line-odd);
    color: var(--color-text-odd);
  }

  & > span:last-child {
    text-align: end;
  }

  #effort {
    color: var(--brand-dashboard);
    font-weight: var(--font-weigth-1);
  }
  #reward {
    color: green;
    text-align: center;
    font-weight: var(--font-weigth-1);
  }

  .tagName {
    display: none;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    align-self: center;
  }

  & > .tagSmall {
    flex: 1;
    min-width: 100px;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
  & > .tagMedium {
    flex: 2;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }
  & > .tagLarge {
    flex: 4;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }

  p {
    color: var(--gray-4);
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  }

  .tagDesc {
    display: none;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .tagDesc {
      display: unset;
    }

    & > span {
      display: flex;
      margin: 5px;
      justify-content: space-between;
    }
  }
`;

export const LoadingProgress = styled.div`
  display: flex;
  width: 130px;
  background-color: var(--color-bg-bar);
  height: 30px;
  justify-content: flex-start;
  align-items: end;
  border-radius: 4px;
  position: relative;
  padding: 2px;

  & > .progress {
    width: ${({ progress }) => `${progress}%`};
    border-radius: 4px;
    height: 100%;
    background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        var(--brand-dashboard) 10px,
        var(--brand-dashboard) 20px
      ),
      linear-gradient(
        to bottom,
        var(--color-bg-bar-progress-1),
        var(--color-bg-bar-progress-2)
      );

    color: transparent;
    background-size: 28px 28px;
    animation: progress-bar 2s linear infinite;
  }

  @keyframes progress-bar {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 28px 0;
    }
  }

  & > p {
    position: absolute;
    top: 8.3px;
    left: 52px;
  }
`;
