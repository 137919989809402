import { HeaderDashboardPools } from "./headerPoolStyle";
import Logo from "../../../assets/logo.png";
import {
  MdDarkMode as MoonDarkMode,
  MdLightMode as SunDarkMode,
} from "react-icons/md";
import { FaCoins as Coin, FaQuestionCircle as Faq } from "react-icons/fa";

import { Divide as Menu } from "hamburger-react";
import { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../../contexts/config";
import { LanguageSwitch } from "../../../components/LanguageSwitch/languageSwitch";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

export const HeaderPool = () => {
  const { t } = useTranslation();

  const { darkMode, handleDarkMode, handleSelected } =
    useContext(ConfigContext);
  const [isOpen, setOpen] = useState(false);
  const [menuClicked, setMenuClicked] = useState(false);

  useEffect(() => {
    if (menuClicked) {
      setOpen(true);
    }
  }, [menuClicked]);

  return (
    <HeaderDashboardPools isOpenMenu={isOpen}>
      <div className="container">
        <figure>
          <a href="/#top">
            <img src={Logo} alt="GopoolCash Logo" />
            <figcaption>ThreePool.Tech</figcaption>
          </a>
        </figure>
        <nav>
          <Menu
            toggled={isOpen}
            toggle={() => {
              setOpen(!isOpen);
              setMenuClicked(true);
            }}
            rounded
            color="var(--gray-5)"
            size={25}
          />
        </nav>
        <div className="optionsMenu desktop__menu">
          <ul>
            <li
              onClick={() => {
                handleSelected();
              }}
            >
              <HashLink to="/#pools">
                <Coin />
                Pools
              </HashLink>
            </li>
            <li onClick={() => handleSelected("faq")}>
              <Faq className="FaqDashboard" />
              FAQ
            </li>
            <li>
              <button onClick={() => handleDarkMode()}>
                {!darkMode ? <MoonDarkMode /> : <SunDarkMode />}
                {!darkMode ? t("header.mod_dark") : t("header.mod_light")}
              </button>
            </li>
            <li>
              <LanguageSwitch />
            </li>
          </ul>
        </div>
      </div>
      <div className="optionsMenu mobile__menu">
        <ul>
          <li onClick={() => handleSelected()}>
            <HashLink to="/#pools">
              <Coin />
              Pools
            </HashLink>
          </li>
          <li onClick={() => handleSelected("faq")}>
            <Faq />
            FAQ
          </li>
          <li>
            <button onClick={() => handleDarkMode()}>
              {!darkMode ? <MoonDarkMode /> : <SunDarkMode />}
              {!darkMode ? t("header.mod_dark") : t("header.mod_light")}
            </button>
          </li>
          <li>
            <LanguageSwitch />
          </li>
        </ul>
      </div>
    </HeaderDashboardPools>
  );
};
