import styled from "styled-components";

export const ModalStyle = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #1c1c1dc7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  padding: 0 12px;

  & > .container {
    width: 100%;
    max-width: 1000px;
    background-color: var(--gray-0);
    border-radius: 12px;
    border: 3px solid var(--brand-dashboard);
    box-shadow: 0px 0px 1rem 0px white;

    & > h2 {
      color: var(--gray-6);
      font-size: 18px;
      text-align: center;
      padding: 20px;
    }
  }

  & > button {
    position: fixed;
    top: 12px;
    right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 2.5rem;
    width: 2.5rem;
    background-color: var(--brand-dashboard);
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--gray-0);
    box-shadow: 0px 0px 0.6rem 0px white;
  }
`;
