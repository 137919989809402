import styled from "styled-components";
import "animate.css";
export const ContainerDashboard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--color-bg-dashboard-pool);
`;

export const ContainerContent = styled.main`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  max-width: 1000px;
  height: 100%;
  margin: 0 auto;
  padding: 0px 8px;

  & > .grafico {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    height: 194px;
    margin-top: 20px;
    background-color: var(--color-bg-dashboard-pool-header);
    border-radius: 6px;
    box-shadow: 6px 5px 4px 0px var(--shadow-dash-pool);

    h1 {
      color: var(--gray-6);
    }
  }

  & > .faq {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > button {
      width: 100px;
      align-self: flex-end;
      padding: 8px;
      border-radius: 4px;
      border-style: none;
      background-color: var(--brand-dashboard);
      color: var(--gray-1);
      font-weight: var(--font-weigth-1);
      box-shadow: 3px 3px 4px 0px var(--shadow-dash-pool);
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > svg {
        width: 20px;
        height: 20px;
      }
    }

    & > button:hover {
      filter: brightness(1.2);
    }
    #FaqDashboard {
      padding: 16px 0px;

      h4 {
        font-size: 18px;
        width: 100%;
        justify-content: space-between;
      }
      details {
        width: 100%;
      }

      summary {
        color: var(--color-text-title-faq);
        letter-spacing: 0.5px;
        width: 100%;
      }

      p {
        width: 100%;
        color: var(--color-text-dash-faq);
        padding: 16px 10px;
        background-color: var(--color-bg-dash-faq);
        border-radius: 6px;
        margin-top: 6px;
        text-align: justify;
        box-shadow: 6px 5px 4px 0px var(--shadow-dash-pool);
        animation: fadeIn 2s ease;
      }

      a {
        color: var(--brand-dashboard);
        font-weight: var(--font-weigth-1);
      }
    }
  }

  @media (min-width: 1000px) {
    & {
      justify-content: space-between;
      padding: unset;
    }

    & > .grafico {
      max-width: 660px;
    }
  }
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  background-color: var(--color-bg-dashboard-pool);
  margin: 0 auto;
  gap: 50px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;

  h1 {
    font-size: 32px;
    color: var(--gray-6);
    display: flex;
    align-items: baseline;
    gap: 4px;
  }
`;
