import { databaseImg } from "../../../services/imgDB/imgDB";
import { SubHeaderContainer } from "./subHeaderPoolStyled";
import Logo from "../../../assets/logo.png";
import { MinerSearch } from "../../../components/MinerSearch/minerSearch";
import { dataMaintenance } from "../../../services/maintenance/maintenanceDb";
import { useContext } from "react";
import { ConfigContext } from "../../../contexts/config";
import { useTranslation } from "react-i18next";
import { PoolDashboardContext } from "../../../contexts/poolDashboard";

export const SubHeader = ({ name }) => {
  const { select, handleSelected, darkMode } = useContext(ConfigContext);
  const { setViewModal } = useContext(PoolDashboardContext);
  const { t } = useTranslation();

  const findMaintenace = dataMaintenance.find((elt) => elt.poolName === name);

  const handleName = () => {
    const verify = /^[A-Za-z]+$/;
    if (verify.test(name)) {
      const arr = name.split("");
      arr[0] = arr[0].toUpperCase();
      return `${arr.join("").toUpperCase()}`;
    } else {
      return name.toUpperCase();
    }
  };

  const criptoData = databaseImg.find((elt) => {
    if (name === "flr_solo") {
      if (!darkMode) {
        return elt.nome === "flora_solo_dark";
      }
      return elt.nome === "flora_solo";
    }
    else if (name === "novo_solo") {
      if (!darkMode) {
        return elt.nome === "novo_solo_dark";
      }
      return elt.nome === "novo_solo";
    }
    else if (name === "kaspa_solo") {
      if (!darkMode) {
        return elt.nome === "kaspa_solo_dark";
      }
      return elt.nome === "kaspa_solo";
    }

    if (name === "flora") {
      if (!darkMode) {
        return elt.nome === "flora_dark";
      }
      return elt.nome === "flora";
    }

    return elt.nome.toLowerCase() === name.toLowerCase();
  });

  return (
    <SubHeaderContainer select={select}>
      <div>
        <button onClick={() => handleSelected(undefined)}>
          <img
            src={criptoData !== undefined ? criptoData.img : Logo}
            alt={`Criptodata ${
              criptoData !== undefined ? criptoData.nome : name
            }`}
          />
          <div>
            <h1>{handleName()}</h1>
            {findMaintenace && <span>{t("dash.main.maintenance")}</span>}
          </div>
        </button>
        <button className="btn_server" onClick={() => setViewModal(true)}>
          {t("dash.main.nav.server")}
        </button>
      </div>
      <ul>
        <li>
          <button
            className={select === "button1" ? "selected" : ""}
            onClick={() => handleSelected("button1")}
          >
            {t("dash.main.nav.payments")}
          </button>
        </li>
        <li>
          <button
            className={select === "button2" ? "selected" : ""}
            onClick={() => handleSelected("button2")}
          >
            {t("dash.main.nav.miners")}
          </button>
        </li>
      </ul>
      <MinerSearch name={name} />
    </SubHeaderContainer>
  );
};
