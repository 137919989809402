import { createContext, useEffect, useState } from "react";

import { cards } from "../services/Pools/connection";

export const LandingPageContext = createContext({});

export const LandingPageProvider = ({ children }) => {
  const [pools, setPools] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await cards().then((res) => setPools(res));
    };
    fetchData();
  }, []);

  return (
    <LandingPageContext.Provider value={{ pools }}>
      {children}
    </LandingPageContext.Provider>
  );
};
