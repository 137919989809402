import { useTranslation } from "react-i18next";
import {
  WorkerCard,
  WorkerList,
  WorkersChange,
  WorkerTitle,
  WorkerWapper,
} from "./workerSectionStyle";

export const WorkerSection = ({ workers, from, setChangeSections }) => {
  const { t } = useTranslation();

  return (
    <WorkerWapper>
      <WorkerTitle>{t("miner_dash.table.workers")}</WorkerTitle>
      {from !== "foundationV1" && (
        <WorkersChange>
          <button>{t("miner_dash.table.workers")}</button>
          <button onClick={() => setChangeSections(false)}>
            {t("miner_dash.table.payments")}
          </button>
        </WorkersChange>
      )}
      <WorkerList>
        {workers?.length ? (
          workers?.map((worker, i) => {
            return (
              <WorkerCard key={i}>
                <p>
                  <span>{t("miner_dash.table.name")}:</span>
                  {worker.worker}
                </p>
                <span>
                  <span>Hashrate:</span>
                  {worker.hashrate}
                </span>
              </WorkerCard>
            );
          })
        ) : (
          <h2>{t("miner_dash.table.notfound.worker")}</h2>
        )}
      </WorkerList>
    </WorkerWapper>
  );
};
